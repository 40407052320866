import { InitialAsset } from '@/models/analysisBacklog/types'
import AnalysisReasonCell from '@/modules/analysisBacklog/components/cell/analysisReason'
import AssetNameCell from '@/modules/analysisBacklog/components/cell/assetName'
import AssetStatusCell from '@/modules/analysisBacklog/components/cell/assetStatus'
import BelongsToCell from '@/modules/analysisBacklog/components/cell/belongsTo'
import DateCell from '@/modules/analysisBacklog/components/cell/date'
import PriorityCell from '@/modules/analysisBacklog/components/cell/priorityCell'
import { filterRegistry } from '@/shared/components/tableComponents/genericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/tableComponents/sortingFunction'
import { analysisBacklogAssetStatusSortingOrder } from '@/shared/components/tableComponents/sortingFunction/constants'
import TableColumn from '@/shared/components/tableComponents/tableColumn'
import { AssetStatusString } from '@/shared/models/types'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { useMemo } from 'react'

const useAnalysisBacklogColumns = () => {
  const columns = useMemo<MRT_ColumnDef<InitialAsset>[]>(
    () => [
      {
        id: 'priority',
        accessorKey: 'priority',
        header: '',
        Cell: ({ row }) => <PriorityCell {...row.original} rowIndex={row.index} />,
        enableColumnFilter: false,
        grow: false,
        size: 50,
      },
      {
        id: 'assetName',
        accessorKey: 'name',
        header: 'Asset Name',
        Header: ({ column }) => <TableColumn column={column} />,
        Cell: ({ cell, row }) => (
          <AssetNameCell assetName={cell.getValue<string>()} hierarchy={row.original.belongsTo.hierarchyPath} />
        ),
        size: 210,
        grow: false,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<InitialAsset>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'belongsTo',
        accessorKey: 'belongsTo.name',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Belongs to',
        Cell: ({ cell }) => {
          return <BelongsToCell belongsTo={cell.getValue<string>()} />
        },
        size: 190,
        grow: false,
        enableColumnFilter: true,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<InitialAsset>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'collectedDate',
        accessorKey: 'lastCollectedDateTime',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Collected',
        Cell: ({ cell }) => <DateCell date={cell.getValue<string | null>()} />,
        size: 150,
        grow: false,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB, columnId: string) =>
          sortingRegistry.date<InitialAsset>(rowA as MRT_Row<InitialAsset>, rowB as MRT_Row<InitialAsset>, columnId),
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.date.filterFn(row as MRT_Row<InitialAsset>, columnId, searchValue),
        meta: {
          filterType: 'date',
        },
      },
      {
        id: 'reportedDate',
        accessorKey: 'lastReportedDateTime',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Reported',
        Cell: ({ cell }) => <DateCell date={cell.getValue<string>()} />,
        size: 150,
        enableColumnFilter: true,
        grow: false,
        sortingFn: (rowA, rowB, columnId: string) =>
          sortingRegistry.date<InitialAsset>(rowA as MRT_Row<InitialAsset>, rowB as MRT_Row<InitialAsset>, columnId),
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.date.filterFn(row as MRT_Row<InitialAsset>, columnId, searchValue),
        meta: {
          filterType: 'date',
        },
      },
      {
        id: 'status',
        accessorKey: 'status',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Status',
        Cell: ({ cell }) => <AssetStatusCell assetStatus={cell.getValue<AssetStatusString>()} />,
        size: 130,
        grow: false,
        enableColumnFilter: true,
        sortingFn: (rowA, rowB, columnId: string) =>
          sortingRegistry.status<InitialAsset>(
            rowA as MRT_Row<InitialAsset>,
            rowB as MRT_Row<InitialAsset>,
            columnId,
            createTableSortingOrderMap(analysisBacklogAssetStatusSortingOrder)
          ),
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.status.filterFn(row as MRT_Row<InitialAsset>, columnId, searchValue),
        meta: {
          filterType: 'status',
        },
      },

      {
        id: 'analysisReason',
        accessorKey: 'analysisReasons',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Analysis Reasons',
        Cell: ({ row }) =>
          row.original.analysisReasons && !row.original.isHandled && <AnalysisReasonCell {...row.original} />,
        enableColumnFilter: true,
        enableSorting: false,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.analysis_reason.filterFn(row as MRT_Row<InitialAsset>, columnId, searchValue),
        meta: {
          filterType: 'analysis_reason',
        },
        minSize: 220,
        maxSize: 500,
      },
    ],
    []
  )

  return columns
}

export default useAnalysisBacklogColumns
