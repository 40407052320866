import { AxiosError } from 'axios'
import networkClient from '@/api/NetworkClient'

import { PATHS } from '@/api/types'
import {
  GetOpenRecommendationsRequest,
  GetOpenRecommendationsResponse,
  GetOpenRecommendationsResult,
} from '@/api/paths/openRecommendations/types'
import { OpenRecommendationData } from '@/models/openRecommendations/types'

const getOpenRecommendations = async (
  handlers: GetOpenRecommendationsResult,
  params: Record<string, string | undefined>
): Promise<OpenRecommendationData | void> => {
  try {
    const response = await networkClient.get<GetOpenRecommendationsRequest, GetOpenRecommendationsResponse>(
      PATHS.GET_OPEN_RECOMMENDATIONS,
      {
        params,
      }
    )
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getOpenRecommendations }
