import { FC } from 'react'
import { Image, StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import { AssetStatusHistory } from '@/models/reportGenerator/types'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'
import { DateFormats, formatDate, parseDate } from '@/shared/dateUtils'
import { getAssetStatusImageSrc } from '@/modules/report-generator/utils'
import { dataTestId } from '@/tests/testid'

const styles = StyleSheet.create(detailedAssetHealth as Styles)
interface ReportAssetStatusHistoryProps {
  statusHistory: AssetStatusHistory
}

const ReportAssetStatusHistory: FC<ReportAssetStatusHistoryProps> = ({ statusHistory }) => {
  return (
    <>
      <Text style={styles.statusHistoryHeading}>End of month status history</Text>
      <View style={styles.statusHistorySection}>
        {statusHistory.history.map((eachMonth, index) => (
          <View style={styles.statusHistoryItem} key={index}>
            {/* <Text style={styles.reportCount}>{eachMonth.reportCount} reports</Text> */}
            <Image
              style={styles.iconSize}
              src={getAssetStatusImageSrc(eachMonth.assetStatus) as string}
              data-testid={dataTestId.reportAssetStatusHistoryIconImage}
            />

            <View style={styles.dateContainer}>
              <Text style={styles.dateText}>{formatDate(eachMonth.date, DateFormats.ShortMonth)}</Text>
              <Text style={styles.dateText}>{parseDate(eachMonth.date).getFullYear()}</Text>
            </View>
          </View>
        ))}
      </View>
    </>
  )
}

export default ReportAssetStatusHistory
