import CopyHierarchyItemButton from '@/modules/analysisBacklog/components/cell/assetName/copyHierarchyItemButton'
import {
  StyledHierarchyLine,
  StyledHierarchyListItem,
  StyledHierarchyText,
  StyledLevelIcon,
} from '@/modules/analysisBacklog/styled'
import { ENV } from '@/shared/constants'
import { copyAssetPathPermissionBySidSelector } from '@/store/selectors/sitesPermissionsSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'
import { useParams } from 'react-router'

interface AssetHierarchyNestedListProps {
  items: string[]
}

const AssetHierarchyNestedList: FC<AssetHierarchyNestedListProps> = ({ items }) => {
  const { sid } = useParams()
  let hasCopyAssetButtonPermission = useTypedSelector((state) => copyAssetPathPermissionBySidSelector(state, sid))

  if (items.length === 0) return null

  if ([ENV.QA.toString(), ENV.STAGING.toString(), ENV.PROD.toString()].includes(import.meta.env.MODE)) {
    hasCopyAssetButtonPermission = true
  }

  return (
    <ul>
      {items.map((item, index) => (
        <StyledHierarchyListItem level={index} key={index} data-testid={dataTestId.assetHierarchyNestedList}>
          <StyledHierarchyLine>
            {index > 0 && (
              <StyledLevelIcon feIcon="reply" feSize="xs" data-testid={dataTestId.assetHierarchyNestedListIcon} />
            )}
            <StyledHierarchyText bold={index === items.length - 1}>{item}</StyledHierarchyText>
            {index >= items.length - 2 && hasCopyAssetButtonPermission && <CopyHierarchyItemButton itemString={item} />}
          </StyledHierarchyLine>
        </StyledHierarchyListItem>
      ))}
    </ul>
  )
}
export default AssetHierarchyNestedList
