import { FilterSummaryProps } from '@/shared/components/tableComponents/genericTableFilter/types'
import { StyledListFilterSummaryContainer } from '@/shared/components/tableComponents/styled'
import { AssetStatus } from '@/shared/models/types'
import { dataTestId } from '@/tests/testid'
import { FC } from 'react'

const AssetStatusFilterSummary: FC<FilterSummaryProps<AssetStatus[]>> = ({ filterValue }) => (
  <StyledListFilterSummaryContainer data-testid={dataTestId.assetStatusFilterSummary}>
    {filterValue.map((v, index) => (
      <li key={index}>{v}</li>
    ))}
  </StyledListFilterSummaryContainer>
)
export default AssetStatusFilterSummary
