import { COLORS } from '@skf-internal/ui-components-react'

export const detailedAssetHealth = {
  heading: {
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 20,
  },
  sectionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 10,
    marginBottom: 10,
  },
  column: {
    flexDirection: 'column',
    gap: 7,
    marginRight: 20,
    maxWidth: 250,
  },
  label: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  value: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  valueWithIcon: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  statusHistoryHeading: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 20,
  },
  statusHistorySection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `2px solid ${COLORS.gray600}`,
  },
  statusHistoryItem: {
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 20,
    marginRight: 8,
  },
  reportCount: {
    fontSize: 8,
    backgroundColor: `${COLORS.gray200}`,
    padding: 2,
    borderRadius: 2,
    marginBottom: 10,
  },
  iconContainer: {
    marginBottom: 10,
  },
  dateContainer: {
    alignItems: 'center',
    flexDirection: 'column',
  },
  dateText: {
    fontSize: 9,
    marginBottom: 2,
  },
  faultHeading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
  },
  faultLabel: {
    fontSize: 9,
    fontWeight: 500,
    marginBottom: 5,
  },
  faultValue: {
    fontSize: 12,
    marginBottom: 10,
  },
  descriptionText: {
    fontSize: 9,
    marginBottom: 10,
  },
  faultDetailsWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  recommendationWrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    gap: 10,
  },
  recommendationColumn: {
    flexDirection: 'column',
    width: '100%',
    gap: 10,
  },
  recommendationHeading: {
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 20,
    marginTop: 15,
  },
  recommendationLabel: {
    fontSize: 9,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  recommendationValue: {
    fontSize: 10,
    fontWeight: 500,
  },
  recommendationDescription: {
    fontSize: 12,
  },
  recommendationDateWrap: {
    flexDirection: 'row',
    gap: 20,
  },
  recommendationDateFields: {
    flexDirection: 'column',
  },
  evidenceWrap: {
    borderBottomWidth: 1,
    borderBottomColor: '#d3d3d3',
    marginBottom: 10,
    paddingBottom: 10,
  },
  evidenceImage: {
    height: 280,
    marginBottom: 10,
  },
  evidenceText: {
    fontSize: 8,
  },
  iconSize: {
    height: 10,
    width: 10,
    marginBottom: 10,
  },
}
