import { PDFViewer } from '@react-pdf/renderer'
import { Button, Card, COLORS, Flexbox, Heading, Image, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledReportGeneratorContainer = styled(Flexbox)`
  margin-top: -2rem;
  height: 100vh;
  overflow: scroll;
  width: 100%;

  > :first-child {
    flex: 0 0 25%;
  }

  > :last-child {
    flex: 0 0 75%;
  }

  @media (width <= 1400px) {
    height: 92vh;
  }
`
export const StyledReportGeneratorLogo = styled(Image)`
  width: 12.5rem;
  height: 6.4375rem;
`
//Asset selection
export const StyledAssetSelectionContainer = styled(Flexbox)`
  width: 20%;
  height: 94vh;
  padding: 0 1rem;
  background: ${COLORS.gray025};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  align-items: center;
  flex-direction: column;

  @media (width <= 1400px) {
    height: calc(100vh + 52%);
  }
`

export const StyledCompanyLogoContainer = styled(Flexbox)`
  height: 10rem;
  justify-content: center;
  align-items: center;
`

export const StyledCompanyLogo = styled.div`
  min-width: 10rem;
  min-height: 7rem;
  padding: 2rem;
`

export const StyledAssetHierarchyContainer = styled(Flexbox)`
  width: 100%;
  height: calc(94vh - 10rem);
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: ${COLORS.white};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  padding: 1.5rem;
  flex-direction: column;

  @media (width <= 1400px) {
    height: calc(100vh + 12%);
  }
`

//Generate Report
export const StyledGenerateReportContainer = styled(Flexbox)`
  width: 100%;
  height: 100%;
`

export const StyledGenerateReportWrap = styled.div`
  width: 100%;
  height: auto;
  padding: 0 2.5rem 0 1rem;
  background-color: ${COLORS.gray300};

  h1 {
    font-size: 2.5rem;
    margin-top: 0;
  }
`

export const StyledGenerateReportFooter = styled(Flexbox)`
  width: calc(100%);
  height: 6rem;
  padding: 0 3.375rem;
  align-items: center;
  gap: 1rem;
  background: ${COLORS.white};
  border-radius: 2px;
  border-top: 1px solid ${COLORS.gray300};
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 25%);
`
export const StyledGenerateReportExportPDFbutton = styled(Button)`
  margin: 1rem 0;
`
export const StyledReportFilters = styled(Flexbox)`
  width: 100%;
  height: fit-content;
  flex-direction: column;
  margin-top: 0.5rem;
  background-color: ${COLORS.white};
`

export const StyledReportFiltersContainer = styled(Flexbox)`
  gap: ${SPACINGS.md};
  min-height: 13rem;
  background-color: ${COLORS.gray100};
  padding: 2rem;
  flex-direction: column;
`

export const StyledSelectSection = styled(Flexbox)`
  width: 100%;
  display: flex;
  gap: ${SPACINGS.md};
`

export const StyledAssetStatusSelectWrap = styled(Flexbox)`
  display: flex;
  justify-content: center;
  align-items: start;
  min-width: 15rem;
`

export const StyledSelectFiltersSection = styled(Flexbox)`
  width: 70%;
  min-width: 40rem;
  gap: ${SPACINGS.md};
  display: flex;
  flex-direction: row;

  @media (width >= 1400px) {
    width: 85%;
  }
`

export const StyledReportDetailsSection = styled(Flexbox)`
  width: 30%;
  gap: ${SPACINGS.md};
  display: flex;
  justify-content: center;
  align-items: start;
`

export const StyledFlexItem = styled(Flexbox)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
`
export const StyledReportDateWrap = styled(Flexbox)`
  label {
    font-weight: 700;
  }
`

export const StyledTotalAssets = styled(Flexbox)`
  width: 25rem;
  font-weight: 700;
  font-size: 0.9rem;
  color: ${COLORS.gray600};
  padding-top: 1.1rem;

  @media (width >= 1400px) {
    font-size: 1.3rem;
    padding-top: 1.5rem;
  }
`

export const StyledIconContainer = styled(Flexbox)`
  min-width: 8rem;
  padding-top: 1.6rem;

  @media (width >= 1400px) {
    gap: 0.25rem;
  }
`

export const StyledReportTypeSection = styled.div`
  width: 100%;
  border-top: 0.12rem solid ${COLORS.gray300};
`

export const StyledReportTypeContainer = styled(Flexbox)`
  font-size: 0.5rem;
  font-weight: 500;
  gap: 2rem;
`

export const StyledReportDateSelectWrap = styled.div`
  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }
`

export const StyledRegenerateButton = styled.button`
  margin-left: auto;
  font-weight: 600;
  color: ${COLORS.gray600};
`
export const StyledReportTypeHeading = styled(Flexbox)`
  padding: 1rem 0;
`

export const StyledReportSelectWrapper = styled.div`
  * {
    flex-wrap: wrap;
  }

  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }

  width: 100%;
`

//reportPreview styles

export const StyledReportPreviewContainer = styled(Flexbox)`
  position: relative;
  width: 100%;
  height: calc(100vh - 32rem);
  background-color: ${COLORS.white};

  @media (width <= 1400px) {
    height: calc(100vh - 11rem);
  }
`

export const StyledReportPreviewCard = styled(Card)`
  margin-bottom: 2rem;
  padding: 2rem;
  width: 100%;
  background: ${COLORS.white};
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
`

export const StyledReportPreviewFlexbox = styled(Flexbox)`
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
`

export const StyledReportPreviewSelectContainer = styled.div`
  width: 9rem;
`

export const StyledReportPreviewPerformanceData = styled.div`
  background-color: #f6f7f8;
  border-radius: 0.125rem;
  padding: 1rem;
  margin-top: 1rem;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
`

export const StyledReportPreviewEmptyState = styled(Flexbox)`
  height: 100%;
  width: 100%;
  background: ${COLORS.white};
  border-radius: 0.25rem;
  gap: 1rem;
`

export const StyledReportPreviewLoaderOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgb(255 255 255 / 80%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const StyledReportPreviewLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
`

export const StyledReportPreviewPDFViewer = styled(PDFViewer)`
  width: 100%;
  height: calc(100vh - 32rem);
  border: none;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgb(0 0 0 / 25%);
  background: ${COLORS.white};

  /* Set white background for PDF viewer */
  iframe {
    background: ${COLORS.white} !important;
  }

  @media (width <= 1400px) {
    height: calc(100vh - 10rem);
  }
`
export const StyledReportPreviewPDFLogo = styled(Image)`
  width: 14.375rem;
  height: 8.75rem;
`

export const StyledReportPreviewLabel = styled.label`
  text-align: left;
  width: 100%;
  font-size: 1rem !important;
`

export const StyledReportGeneratorBackButton = styled(Button)<{
  backgroundColor: string
}>`
  width: 9rem;
  font-size: 0.65rem;
  height: 2rem;
  margin: 1.9rem 0;
  background-color: ${(props) => props.backgroundColor};

  span {
    margin-inline: 0;

    svg {
      width: 1rem;
    }
  }

  @media (width <= 1400px) {
    margin: 2.15rem 0;
  }
`
export const StyledPDFPreviewHiddenComponentWrapper = styled.div`
  position: absolute;
  left: -9999px;
`

//Cover page

export const StyledReportCoverPageContainer = styled(Flexbox)`
  height: 100%;
  width: 100%;
  background: ${COLORS.white};
  gap: 1rem;
  margin: 4rem 0;
`
export const StyledReportCoverPageContainerRow = styled(Flexbox)`
  height: 100%;
  width: 40rem;
  background: ${COLORS.white};
  gap: 1rem;

  h2 {
    font-size: 3rem;
    margin: 2rem 3.5rem;
  }
`

export const StyledCoverPageLogoContainer = styled.div`
  min-width: 10rem;
  min-height: 7rem;
  padding: 2rem;
`
export const StyledCoverPageLogo = styled(Image)`
  width: 21.875rem;
  height: 12.5rem;
`

export const StyledReportGeneratorLabel = styled.label`
  width: 100%;
  font-size: 2rem !important;
  font-weight: 700;
  text-align: end;
`
//info page
export const StyledReportInfoPageContainer = styled(Flexbox)`
  height: 100%;
  width: 100%;
  background: ${COLORS.white};
  gap: 1rem;

  h1 {
    font-weight: 500;
  }
`
export const StyledReportInfoPageContainerRow = styled(Flexbox)`
  height: 100%;
  width: 54rem;
  background: ${COLORS.white};
  gap: 1rem;

  h3 {
    font-size: 1rem;
  }
`
export const StyledReportInfoPageLegendRow = styled(Flexbox)`
  height: 100%;
  width: 58rem;
  background: ${COLORS.white};
`
export const StyledReportInfoPageLegendHeader = styled(Flexbox)`
  height: 100%;
  width: 58rem;
  margin-bottom: -2rem;
  background: ${COLORS.white};
`
export const StyledReportInfoPageAssetStatusIconContainer = styled(Flexbox)`
  height: 1.5rem;
`
export const StyledReportInfoPageLastMeasurementReportRow = styled(Flexbox)`
  height: 100%;
  width: 29rem;
  background: ${COLORS.white};
  margin-left: 3rem;
`
export const StyledReportInfoPageLastMeasurementReportContainer = styled(Flexbox)`
  height: 100%;
  width: 54rem;
  background: ${COLORS.white};
  gap: 1rem;

  h3 {
    font-size: 1rem;
    margin-bottom: -0.5rem;
  }
`
export const StyledReportInfoPageLegendContainer = styled(Flexbox)`
  height: 33.5rem;
  margin-bottom: 1rem;
`
export const StyledReportInfoPageLegendHeading = styled(Heading)`
  margin-bottom: 1rem;
`

//asset annual status icon
export const StyledAssetAnnualStatusContainer = styled.div`
  position: relative;
  width: 19px;
  height: 40px;
`

export const StyledStripeBox = styled.div`
  position: relative;
  top: 0.4rem;
  width: 19px;
  height: 40px;
  border: 1px solid ${COLORS.black};
  background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2' stroke='black' stroke-width='1'/%3E%3C/svg%3E");
  background-color: white;
`
