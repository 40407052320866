import { StyledOpenRecommendationAssetName } from '@/modules/openRecommendations/styled'
import { convertToDisplayValue } from '@/shared/utils'
import { FC } from 'react'

interface OpenRecommendationAssetNameProps {
  assetName: string
}

const OpenRecommendationAssetName: FC<OpenRecommendationAssetNameProps> = ({ assetName }) => {
  return <StyledOpenRecommendationAssetName>{convertToDisplayValue(assetName)}</StyledOpenRecommendationAssetName>
}

export default OpenRecommendationAssetName
