import { DeviceInterface, DeviceStatusEnum, UniqueChannelsAssets } from '@/models/devicesAndSensors/types'
import LastCommunicationCell from '@/modules/devicesSensors/components/commonCells/lastCommunicationCell'
import StatusCell from '@/modules/devicesSensors/components/commonCells/statusCell'
import DeviceConnectedAssetsCell from '@/modules/devicesSensors/components/deviceCells/deviceConnectedAssetsCell'
import DeviceNumberCell from '@/modules/devicesSensors/components/deviceCells/deviceNumberCell'
import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import useGetHeaderCharactersLength from '@/modules/devicesSensors/hooks/useGetHeaderCharactersLength'
import { filterRegistry } from '@/shared/components/tableComponents/genericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/tableComponents/sortingFunction'
import { devicesAndSensorsStatusSortingOrder } from '@/shared/components/tableComponents/sortingFunction/constants'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import TableColumn from '@/shared/components/tableComponents/tableColumn'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'

const useDevicesColumnsDefinition = () => {
  const characterLengths = useGetCharactersLength()
  const columnHeaderCharacterLength = useGetHeaderCharactersLength()

  const columns = useDeepCompareMemo<MRT_ColumnDef<DeviceInterface>[]>(
    () => [
      {
        accessorKey: 'deviceNumber',
        id: 'deviceNumber',
        header: '',
        size: 70,
        enableColumnFilter: false,
        Cell: ({ cell }) => <DeviceNumberCell deviceNumber={cell.getValue<number>()} />,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.number.filterFn(row as MRT_Row<DeviceInterface>, columnId, searchValue),
        grow: false,
      },
      {
        accessorKey: 'deviceName',
        header: 'Device',
        Header: ({ column }) => <TableColumn column={column} />,
        minSize: 190,
        maxSize: 300,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        Cell: ({ cell }) => (
          <TextEllipsis
            value={cell.getValue<string>()}
            characterLength={characterLengths.name}
            type={TextEllipsisType.SingleLine}
          />
        ),
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<DeviceInterface>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
      {
        accessorKey: 'status',
        header: '',
        size: 70,
        grow: false,
        Header: ({ column }) => <TableColumn column={column} />,
        Cell: ({ cell }) => <StatusCell sensorStatus={cell.getValue<DeviceStatusEnum>()} />,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.device_status.filterFn(row as MRT_Row<DeviceInterface>, columnId, searchValue),
        meta: {
          filterType: 'device_status',
        },
        sortingFn: (rowA, rowB, columnId: string) =>
          sortingRegistry.status<DeviceInterface>(
            rowA as MRT_Row<DeviceInterface>,
            rowB as MRT_Row<DeviceInterface>,
            columnId,
            createTableSortingOrderMap(devicesAndSensorsStatusSortingOrder)
          ),
      },
      {
        accessorKey: 'lastCommunicationDate',
        header: 'Last Communication',
        Header: ({ column }) => (
          <TableColumn column={column} characterLengths={columnHeaderCharacterLength.lastCommunicationDate} />
        ),
        size: 220,
        grow: false,
        Cell: ({ cell }) => <LastCommunicationCell lastCommunicationDate={cell.getValue<string | undefined>()} />,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.date.filterFn(row as MRT_Row<DeviceInterface>, columnId, searchValue),
        meta: {
          filterType: 'date',
        },
      },
      {
        accessorKey: 'uniqueChannelsAssets',
        header: 'Connected Assets',
        Header: ({ column }) => (
          <TableColumn column={column} characterLengths={columnHeaderCharacterLength.assetName} />
        ),
        minSize: 200,
        maxSize: 300,
        Cell: ({ cell }) => <DeviceConnectedAssetsCell connectedAssets={cell.getValue<UniqueChannelsAssets[]>()} />,
        filterFn: (row, columnId, searchValue) =>
          filterRegistry.text.filterFn(row as MRT_Row<DeviceInterface>, columnId, searchValue),
        meta: {
          filterType: 'text',
        },
      },
    ],
    [characterLengths]
  )

  return columns
}

export default useDevicesColumnsDefinition
