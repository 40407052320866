import { AssetStatusString } from '@/shared/models/types'

export enum ClosedRaOutcome {
  completed = 'Completed',
  rejected = 'Rejected',
}
export type ClosedRaOutcomeKeys = keyof typeof ClosedRaOutcome

export interface RecommendationData {
  assetID: string
  assetName: string
  assetParent: string
  assetStatus: AssetStatusString | ClosedRaOutcomeKeys
  faultId: string
  faultType: string
  observation: string
  raOpenDate: string
  recommendedAction: string
  raDueDate: string
  raComment: string
  raDaysToDue: null | number
  raOutcome: null | ClosedRaOutcomeKeys
  raID: string
  isClosed: boolean
}

export type OpenRecommendationData = {
  open: RecommendationData[]
  closed: RecommendationData[]
}

export enum RecommendationState {
  Closed = 'Closed',
  Open = 'Open',
  DefaultDate = '-',
}
export enum ClosedRecommendedActionState {
  dueInDays = '-99999',
}

export type DateFilter = {
  dateFilterType: 'after' | 'before' | 'between'
  startDate?: Date
  endDate?: Date
}
