import { AppliedFilterDataType } from '@/shared/components/tableComponents/genericTableFilter/types'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Popup, Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface AppliedFilterPopoverProps {
  isPopupShown: boolean
  hoveredElement: HTMLElement | null
  appliedFilterValue: AppliedFilterDataType
  FilterSummaryComponent: FC<{ filterValue: AppliedFilterDataType }>
}

/**
 * A popover component that displays applied filter information.

 * @param {boolean} isPopupShown - Whether the popover should be shown.
 * @param {RefObject<HTMLDivElement>} hoveredElement - A reference to the element that triggers the popover.
 * @param {AppliedFilter} appliedFilterValue - The applied filter value.
 * @param {React.FC<AppliedFilterProps>} FilterSummaryComponent - A component to render the detailed filter information.
 * @returns {JSX.Element} The rendered popover component.
 */
const AppliedFilterPopover: FC<AppliedFilterPopoverProps> = ({
  isPopupShown,
  hoveredElement,
  appliedFilterValue,
  FilterSummaryComponent,
}) => {
  return (
    <Popup isHovered={isPopupShown} triggerElement={hoveredElement}>
      <Flexbox feFlexDirection="column" feGap="sm" data-testid={dataTestId.appliedFilterPopover}>
        <Text feFontSize="md" feFontWeight="bold">
          The following filter values are applied:
        </Text>
        {appliedFilterValue && <FilterSummaryComponent filterValue={appliedFilterValue} />}
      </Flexbox>
    </Popup>
  )
}

export default AppliedFilterPopover
