import { FC } from 'react'
import { StyleSheet, Styles, View } from '@react-pdf/renderer'
import { DetailedAssetRecommendedAction } from '@/models/reportGenerator/types'
import ReportRecommendedActionField from '@/modules/report-generator/components/reports/detailedAssetHealth/reportRecommendedActionField'
import ReportRecommendedActionDateField from '@/modules/report-generator/components/reports/detailedAssetHealth/reportRecommendedActionDateField'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'

const styles = StyleSheet.create(detailedAssetHealth as Styles)
interface ReportAssetRecommendedActionProps {
  recommendedAction: DetailedAssetRecommendedAction
  index: number
}

const ReportAssetRecommendedAction: FC<ReportAssetRecommendedActionProps> = ({ recommendedAction, index }) => {
  return (
    <View style={styles.recommendationWrap}>
      <View style={styles.recommendationColumn}>
        <ReportRecommendedActionField
          label={`Recommended Action ${index + 1}`}
          value={recommendedAction.recommendation}
        />
        <ReportRecommendedActionField label={`Analyst Comment`} value={recommendedAction.comment} />
      </View>
      <View style={styles.recommendationColumn}>
        <ReportRecommendedActionField label={`Work Order`} value={recommendedAction.workOrder} />
        <View style={styles.recommendationDateWrap}>
          <ReportRecommendedActionDateField label={'Entered Date'} value={recommendedAction.createdDate} />
          <ReportRecommendedActionDateField label={'Due Date'} value={recommendedAction.dueDate} />
        </View>
      </View>
    </View>
  )
}

export default ReportAssetRecommendedAction
