import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import ModuleHeader from '@/shared/components/ModuleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { setModuleHeaderActionPanelData, setModuleHeaderConfigData } from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { initialReportFiltersState } from '@/modules/report-generator/utils/constants'
import { isEqual } from '@/shared/utils'
import { clearReportGeneratorFilters } from '@/modules/report-generator/utils'
import { batchActions } from '@/store/batch/actions'

const ReportGeneratorHeader = () => {
  const reportGeneratorHeaderDispatch = useAppDispatch()
  const {
    generatedReportData,
    isReportFiltersApplied,
    setIsReportFiltersApplied,
    setAssetReportFilters,
    assetReportFilters,
  } = useReportGeneratorContext()

  useDeepCompareEffect(() => {
    const checkFiltersDifference = isEqual(
      { ...assetReportFilters, reportDate: undefined },
      { ...initialReportFiltersState, reportDate: undefined }
    )
    setIsReportFiltersApplied(!checkFiltersDifference)
  }, [assetReportFilters])

  useDeepCompareEffect(() => {
    reportGeneratorHeaderDispatch(
      batchActions([
        setModuleHeaderConfigData({
          showHeader: true,
          title: 'Report Generator',
          showInfoPanel: false,
          showActionPanel: true,
          subtitle: '',
          showSubtitle: false,
        }),
        setModuleHeaderActionPanelData({
          withLoader: true,
          withCSVDownloadButton: false,
          csvDownLoadButtonSize: 'lg',
          isClearFiltersButtonDisabled: !isReportFiltersApplied,
          withClearFiltersButton: true,
          clearFiltersButtonFn: () => clearReportGeneratorFilters(setAssetReportFilters),
          withRefreshButton: false,
          withTableStateChangeSwitch: false,
        }),
      ])
    )
  }, [generatedReportData, isReportFiltersApplied])

  return <ModuleHeader />
}

export default ReportGeneratorHeader
