import { RecommendationData } from '@/models/openRecommendations/types'
import { ROUTES } from '@/shared/constants'
import useScrollDetector from '@/shared/hooks/useScrollDetection'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { pushRedirectData } from '@/store/redirectData/actions'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { IconButton, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { MRT_TableInstance } from 'material-react-table'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router'

type RedirectButtonProps = {
  assetId: RecommendationData['assetID']
  currentSelectedRowIndex: number
  table: MRT_TableInstance<RecommendationData>
}

export const RedirectButton: FC<RedirectButtonProps> = ({ assetId, table, currentSelectedRowIndex }) => {
  const navigate = useNavigate()

  const { sid } = useParams()

  const { position } = useScrollDetector(table.refs.tableContainerRef.current ?? undefined)

  const redirectButtonDispatch = useAppDispatch()

  const { tableStateChangeSwitchCurrentState } = useTypedSelector((state) => headerActionPanelConfigSelector(state))

  const [hoverRef, isHovered] = useTooltip()
  const handleClick = () => {
    sessionStorage.setItem(STORAGE_REPORT_STATUS_ORIGIN, `${ROUTES.OPEN_RECOMMENDATIONS}/${sid}`)
    const { columnFilters, sorting, pagination } = table.getState()
    redirectButtonDispatch(
      pushRedirectData({
        sid: sid as string,
        toModule: ROUTES.REPORT_STATUS,
        fromModule: ROUTES.OPEN_RECOMMENDATIONS,
        columnFilters,
        sorting,
        tablePaginationState: pagination,
        tableScrollOffset: position,
        tableSelectedRowIndex: currentSelectedRowIndex,
        tableSwitchCurrentState: tableStateChangeSwitchCurrentState,
        assetId,
      })
    )
    navigate(`${ROUTES.REPORT_STATUS}/${assetId}`)
  }

  return (
    <div onClick={() => handleClick()} data-testid={dataTestId.openRecommendationsRedirectButton}>
      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        Report status
      </Tooltip>
      <IconButton feIcon="openInNew" as="button" aria-label="Invalid Input" feSize="sm" ref={hoverRef} />
    </div>
  )
}
