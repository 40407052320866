import { FC } from 'react'
import { StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'
import { DateFormats, formatDate } from '@/shared/dateUtils'

const styles = StyleSheet.create(detailedAssetHealth as Styles)

interface ReportRecommendedActionDateFieldProps {
  label: string
  value: string | null
}

const ReportRecommendedActionDateField: FC<ReportRecommendedActionDateFieldProps> = ({ label, value }) => {
  return (
    <View style={styles.recommendationDateFields}>
      <Text style={styles.recommendationLabel}>{label}</Text>
      <Text style={styles.recommendationValue}>{formatDate(value as string, DateFormats.ISO8601Date)}</Text>
    </View>
  )
}

export default ReportRecommendedActionDateField
