import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Switch } from '@skf-internal/ui-components-react'
import { useCallback } from 'react'

/**
 * HeaderTableStateChangeSwitch is a toggle switch component that allows users to change
 * the state of a table (e.g., enabling or disabling a feature related to the table).
 * It displays a label and can be enabled or disabled based on the current data fetching status.
 *
 * The switch uses data from the Redux store to determine its label, current state, and
 * the function to call when the switch is toggled. While data is being fetched, the switch
 * can be disabled to prevent user interaction.
 *
 * @returns {JSX.Element} A toggle switch for changing the table state.
 */

const HeaderTableStateChangeSwitch = () => {
  const { fetchDataStatus } = useFetchDataContext()

  const headerTableStateChangeSwitchDispatch = useAppDispatch()

  const headerActionPanelConfig = useTypedSelector((store) => headerActionPanelConfigSelector(store))

  const { tableStateChangeSwitchLabel, isAsyncIndicatorShown, tableStateChangeSwitchCurrentState } =
    headerActionPanelConfig

  const handleChange = useCallback(() => {
    headerTableStateChangeSwitchDispatch(
      setModuleHeaderActionPanelData({
        ...headerActionPanelConfig,
        tableStateChangeSwitchCurrentState: !tableStateChangeSwitchCurrentState,
        isTableStateAutoSwitched: false,
      })
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableStateChangeSwitchCurrentState, headerActionPanelConfig])

  return (
    <Switch
      disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
      feLabel={tableStateChangeSwitchLabel || ''}
      checked={tableStateChangeSwitchCurrentState}
      onChange={handleChange}
      data-testid={dataTestId.headerTableStateChangeSwitch}
    />
  )
}

export default HeaderTableStateChangeSwitch
