import { ROUTES } from '@/shared/constants'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { deleteRedirectData } from '@/store/redirectData/actions'
import { redirectDataBySidSelector } from '@/store/selectors/redirectDataSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { MRT_Row, MRT_RowData, MRT_SortingState, MRT_TableInstance } from 'material-react-table'
import { Dispatch, useEffect } from 'react'
import { useParams } from 'react-router'

type PreviousDataProps<T extends MRT_RowData, V> = {
  table: MRT_TableInstance<T>
  setCurrentSelectedRow?: (value: React.SetStateAction<T | undefined>) => void
  tableData?: V
  setSorting?: Dispatch<React.SetStateAction<MRT_SortingState>>
}

const usePreviousData = <T extends MRT_RowData, V>({
  table,
  tableData,
  setCurrentSelectedRow,
  setSorting,
}: PreviousDataProps<T, V>) => {
  const { sid } = useParams()
  const redirectData = useTypedSelector((state) => redirectDataBySidSelector(state, sid))

  const previousPageDataDispatch = useAppDispatch()

  const tableState = table.getState()

  const tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  const tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  const sortedRows = useDeepCompareMemo(
    () => table.getSortedRowModel().rows as MRT_Row<T>[],
    [tableData, tableSorting, tableColumnFilters]
  )

  useEffect(() => {
    if (
      redirectData &&
      (redirectData.toModule === redirectData.fromModule || redirectData.fromModule === ROUTES.REPORT_STATUS)
    ) {
      const {
        columnFilters,
        sorting,
        tablePaginationState,
        tableScrollOffset,
        tableSelectedRowIndex,
        tableSwitchCurrentState,
        assetId,
      } = redirectData
      const newTableState = { ...table.getState() }
      if (columnFilters) {
        newTableState.columnFilters = columnFilters
      }

      if (sorting && setSorting) {
        setSorting(sorting)
      }

      table.setState(newTableState)
      if (tableSwitchCurrentState !== undefined) {
        previousPageDataDispatch(
          setModuleHeaderActionPanelData({
            tableStateChangeSwitchCurrentState: tableSwitchCurrentState,
            isTableStateAutoSwitched: true,
          })
        )
      }
      if (tablePaginationState) {
        setTimeout(
          (tablePaginationState) => {
            table.setPageIndex(tablePaginationState.pageIndex)
          },
          500,
          tablePaginationState
        )
      }
      if (tableScrollOffset !== undefined && tableScrollOffset !== null) {
        setTimeout(
          (tableScrollOffset) => {
            table.refs.tableContainerRef.current?.scrollTo({ top: tableScrollOffset })
          },
          500,
          tableScrollOffset
        )
      }

      if (assetId !== undefined && tableSelectedRowIndex !== undefined) {
        setTimeout(
          (assetId) => {
            const rowToSelect = table.getSortedRowModel().rows.find((item) => {
              if ('raID' in item.original) {
                return item.original.raID === assetId
              }
              return item.original.id === assetId
            })
            const nextRowToSelect = table.getSortedRowModel().rows.find((item) => item.index > tableSelectedRowIndex)
            if (setCurrentSelectedRow) {
              if (rowToSelect) {
                if (rowToSelect.original.isHandled || rowToSelect.original.isClosed) {
                  setCurrentSelectedRow(nextRowToSelect?.original)
                } else {
                  setCurrentSelectedRow(rowToSelect.original)
                }
              } else {
                setCurrentSelectedRow(nextRowToSelect?.original)
              }
            }
          },
          750,
          assetId
        )
      }

      if (redirectData.fromModule === ROUTES.REPORT_STATUS) {
        setTimeout(() => {
          previousPageDataDispatch(deleteRedirectData(sid as string))
        }, 1500)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRows, sid, redirectData, table])
}

export default usePreviousData
