import { SET_SITES_PERMISSIONS } from '@/store/sitesPermissions/actions.types'
import { SitesPermissionsAction, SitesPermissionsState } from '@/store/sitesPermissions/types'

export const initialState: SitesPermissionsState = {}

export type SitesPermissionsReducer = (
  state: SitesPermissionsState | undefined,
  action: SitesPermissionsAction
) => SitesPermissionsState

const sitesPermissionsReducer: SitesPermissionsReducer = (state = initialState, action) => {
  if (action.type === SET_SITES_PERMISSIONS) {
    return action.payload
  }

  return state
}

export default sitesPermissionsReducer
