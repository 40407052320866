import { Card, Flexbox, FONT_SIZES, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledGenericFilterPopoverCard = styled(Card)`
  top: ${SPACINGS.sm};
  gap: ${SPACINGS.md};
  display: flex;
  column-gap: ${SPACINGS.xxl};
  flex-direction: column;
  border: none !important;
  box-shadow: none !important;

  * {
    font-size: 1rem !important;
  }

  & button span {
    font-size: 0.875rem !important;
  }
`

export const StyledDateFilterContentContainer = styled(Flexbox)`
  width: 31rem;
`

export const StyledListFilterSummaryContainer = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  & > li {
    font-size: ${FONT_SIZES.md};
  }
`

export const StyledDeviceStatusFilterRoot = styled(Flexbox)`
  width: 12.5rem;
`

export const StyledDeviceStatusFilterSummaryContainer = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  margin: 0;
  padding: 0;

  & > li {
    font-size: ${FONT_SIZES.md};
  }
`

export const StyledAllProcessedStateContainer = styled(Flexbox)`
  margin-top: ${SPACINGS.sm};
`
