import { DateFilterValue, FilterSummaryProps } from '@/shared/components/tableComponents/genericTableFilter/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { Text } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const DateFilterSummary: FC<FilterSummaryProps<DateFilterValue>> = ({ filterValue }) => {
  if (
    (filterValue.dateFilterType === 'before' || filterValue.dateFilterType === 'after') &&
    filterValue.startDate !== undefined
  ) {
    return (
      <Text feFontSize="md" data-testid={dataTestId.dateFilterSummary}>
        Dates after <b>{formatDate(filterValue.startDate, DateFormats.AmericanDateFormat)}</b>
      </Text>
    )
  } else if (filterValue.dateFilterType === 'between' && filterValue.startDate && filterValue.endDate) {
    return (
      <Text feFontSize="md" data-testid={dataTestId.dateFilterSummary}>
        Dates between <b>{formatDate(filterValue.startDate, DateFormats.AmericanDateFormat)}</b> and{' '}
        <b>{formatDate(filterValue.endDate, DateFormats.AmericanDateFormat)}</b>
      </Text>
    )
  }
  return null
}

export default DateFilterSummary
