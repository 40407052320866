import { FC } from 'react'
import { ReportFault } from '@/models/reportGenerator/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import ReportAssetRecommendedAction from '@/modules/report-generator/components/reports/detailedAssetHealth/reportAssetRecommendedAction'
import ReportAssetEvidence from '@/modules/report-generator/components/reports/detailedAssetHealth/reportAssetEvidence'
import ReportField from '@/modules/report-generator/components/reports/detailedAssetHealth/reportField'
import ReportStatus from '@/modules/report-generator/components/reports/detailedAssetHealth/reportStatusField'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'

const styles = StyleSheet.create(detailedAssetHealth as Styles)

interface ReportAssetFaultProps {
  fault: ReportFault
}

const ReportAssetFault: FC<ReportAssetFaultProps> = ({ fault }) => {
  return (
    <View key={fault.faultId}>
      <Text style={styles.faultHeading}>Fault #{fault.faultId}</Text>
      {/* Fault Information */}
      <View style={styles.faultDetailsWrap}>
        <ReportField label="Fault" value={fault.faultType} />
        <ReportStatus label="Status" status={fault.faultStatus} />
        <ReportField label="Reported Date" value={formatDate(fault.dateReported, DateFormats.ISO8601Date)} />
        <ReportField label="Collected Date" value={formatDate(fault.dataCollectedDate, DateFormats.ISO8601Date)} />
      </View>
      <View>
        <Text style={styles.label}>Observation</Text>
        <Text style={styles.descriptionText}>{fault.observation}</Text>
      </View>

      {/* Recommended Actions */}
      {fault.recommendedActions.length > 0 && (
        <View>
          <Text style={styles.recommendationHeading}>Recommended Actions</Text>
          {fault.recommendedActions.map((recommendedAction, index) => (
            <ReportAssetRecommendedAction
              recommendedAction={recommendedAction}
              index={index}
              key={recommendedAction.raId}
            />
          ))}
        </View>
      )}

      {/* Evidence Section */}
      {fault.evidences.length > 0 && (
        <View>
          <Text style={styles.recommendationHeading}>Fault #{fault.faultId} Evidence</Text>
          {fault.evidences.map((evidence) => (
            <ReportAssetEvidence key={evidence.fileID} evidence={evidence} />
          ))}
        </View>
      )}
    </View>
  )
}

export default ReportAssetFault
