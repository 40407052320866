import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import { AssetStatusString, RecommendedActionBase } from '@/shared/models/types'

export type Status = Exclude<AssetStatusString, 'never-reported'>

export type NonNormalStatus = Exclude<AssetStatusString, 'normal' | 'never-reported'>

export enum FaultState {
  NEW = 'new',
  OPEN = 'open',
  EDIT = 'edit',
  PENDING_CLOSE = 'pending_close',
  CLOSED = 'closed',
}

export enum RecommendedActionState {
  NEW = 'new',
  OPEN = 'open',
  EDIT = 'edit',
  CLOSED = 'closed',
  PENDING_CLOSE = 'pending_close',
}

export type NewRecommendedAction = RecommendedActionBase & {
  state: RecommendedActionState.NEW
}

export type OpenRecommendedAction = RecommendedActionBase & {
  state: RecommendedActionState.OPEN
  id: string
}

export type ClosedRecommendedAction = RecommendedActionBase & {
  state: RecommendedActionState.CLOSED | RecommendedActionState.PENDING_CLOSE
  id: string
  outcome: 'completed' | 'rejected'
  closeDate: string
  username: string | null
}

export type RecommendedAction = NewRecommendedAction | OpenRecommendedAction | ClosedRecommendedAction

type FaultType = {
  code: string
  text: string
  subTypes: {
    code: string
    en: string
    deprecated: boolean
  }[]
}

type FaultBase = {
  fault: FaultType['code']
  collectionDate: string
  createdDate: string | null
  observation: string
  evidences: EvidenceImageData[]
}

export type NewFault = FaultBase & {
  state: FaultState.NEW
  recommendedActions: NewRecommendedAction[]
  status: NonNormalStatus
}

export type OpenFault = FaultBase & {
  state: FaultState.OPEN | FaultState.EDIT
  id: string
  recommendedActions: RecommendedAction[]
  status: NonNormalStatus
}

export type ClosedFault = FaultBase & {
  state: FaultState.CLOSED
  id: string
  correctDiagnostic: boolean
  explanation: string
  recommendedActions: ClosedRecommendedAction[]
  status: Extract<AssetStatusString, 'normal'>
}

export type Fault = NewFault | OpenFault | ClosedFault

export interface FaultsFormState {
  faults: Fault[]
  initialStateFaults: Fault[]
  isSubmitting: boolean
  isDirty: boolean
  lastCollectionDate: string
  errors?: FaultErrorState[]
  isValid?: boolean
}

export type UpdateFaultPath = {
  fieldName: string
  fieldId: string
  parentFieldId?: string
}

export type RecommendedActionErrorState = {
  id: string
  recommendedAction: FieldErrorState
  dueDate: FieldErrorState
  additionalNotes: FieldErrorState
  workOrder: FieldErrorState
  outcome: FieldErrorState
  closeDate: FieldErrorState
}

export type FaultErrorState = {
  id: string
  fault: FieldErrorState
  collectionDate: FieldErrorState
  status: FieldErrorState
  observation: FieldErrorState
  explanation: FieldErrorState
  recommendedActions: RecommendedActionErrorState[]
}

export type FieldErrorState = {
  error: string
  isValid: boolean
}
