import { AnalysisReasonStringType } from '@/models/analysisBacklog/types'
import {
  analysisReasonFilterFn,
  dateFilterFn,
  deviceStatusFilterFn,
  numberFilterFn,
  statusFilterFn,
  textSearchFilterFn,
} from '@/shared/components/tableComponents/genericTableFilter/filterFunctions'
import AnalysisReasonContent from '@/shared/components/tableComponents/genericTableFilter/filterPopoverContents/AnalysisReasonContent'
import DateFilterContent from '@/shared/components/tableComponents/genericTableFilter/filterPopoverContents/DateFilterContent'
import DeviceStatusFilterContent from '@/shared/components/tableComponents/genericTableFilter/filterPopoverContents/DeviceStatusFilterContent'
import NumberFilterContent from '@/shared/components/tableComponents/genericTableFilter/filterPopoverContents/NumberFilterContent'
import AssetStatusFilterContent from '@/shared/components/tableComponents/genericTableFilter/filterPopoverContents/AssetStatusFilterContent'
import TextFilterContent from '@/shared/components/tableComponents/genericTableFilter/filterPopoverContents/TextFilterContent'
import AnalysisReasonFilterSummary from '@/shared/components/tableComponents/genericTableFilter/filterSummaries/AnalysisReasonFilterSummary'
import DateFilterSummary from '@/shared/components/tableComponents/genericTableFilter/filterSummaries/DateFilterSummary'
import DeviceStatusFilterSummary from '@/shared/components/tableComponents/genericTableFilter/filterSummaries/DeviceStatusFilterSummary'
import AssetStatusFilterSummary from '@/shared/components/tableComponents/genericTableFilter/filterSummaries/AssetStatusFilterSummary'
import TextFilterSummary from '@/shared/components/tableComponents/genericTableFilter/filterSummaries/TextFilterSummary'
import {
  FilterRegistryEntry,
  FilterRowType,
  FilterType,
} from '@/shared/components/tableComponents/genericTableFilter/types'

const filterRegistry: Record<FilterType, FilterRegistryEntry<FilterRowType>> = {
  text: {
    FilterComponent: TextFilterContent,
    FilterSummaryComponent: TextFilterSummary,
    filterFn: textSearchFilterFn,
  },
  date: {
    FilterComponent: DateFilterContent,
    FilterSummaryComponent: DateFilterSummary,
    filterFn: dateFilterFn,
  },
  status: {
    FilterComponent: AssetStatusFilterContent,
    FilterSummaryComponent: AssetStatusFilterSummary,
    filterFn: statusFilterFn,
  },
  analysis_reason: {
    FilterComponent: AnalysisReasonContent,
    FilterSummaryComponent: AnalysisReasonFilterSummary,
    filterFn: analysisReasonFilterFn,
  },
  number: {
    FilterComponent: NumberFilterContent,
    FilterSummaryComponent: TextFilterSummary,
    filterFn: numberFilterFn,
  },
  device_status: {
    FilterComponent: DeviceStatusFilterContent,
    FilterSummaryComponent: DeviceStatusFilterSummary,
    filterFn: deviceStatusFilterFn,
  },
} as unknown as Record<FilterType, FilterRegistryEntry<FilterRowType>>

const analysisReasonsString: Record<AnalysisReasonStringType, string> = {
  ai: 'AI alarm',
  band_alarm: 'Band alarm',
  band_alert: 'Band alert',
  device_fault: 'Sensor fault',
  diagnostics_alarm: 'Diagnostics alarm',
  diagnostics_alert: 'Diagnostics alert',
  feedback: 'Feedback',
  no_sensor_data: 'No sensor data',
  overall_alarm: 'Overall alarm',
  overall_alert: 'Overall alert',
  protean_alarm: 'Protean alarm',
  no_data_24h: 'No Measurements 24h',
}

export { filterRegistry, analysisReasonsString }
