import { RecommendationState } from '@/models/openRecommendations/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { convertToDisplayValue } from '@/shared/utils'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationDateProps {
  date: string
}

const OpenRecommendationDate: FC<OpenRecommendationDateProps> = ({ date }) => {
  return (
    <Flexbox>
      {convertToDisplayValue(date ? formatDate(date, DateFormats.AmericanDateFormat) : RecommendationState.DefaultDate)}
    </Flexbox>
  )
}
export default OpenRecommendationDate
