import { ENV } from '@/shared/constants'
import { StyledLogoDivider } from './styled'
import { Text } from '@skf-internal/ui-components-react'

export const envTitle: Record<ENV, string> = {
  [ENV.DEV]: 'DEV',
  [ENV.QA]: 'QA',
  [ENV.STAGING]: 'STAGING',
  [ENV.BUILD_DEV]: 'DEV',
  [ENV.PROD]: '',
}

export const EnvTitle = () => {
  const env = import.meta.env.MODE as ENV

  return (
    <>
      <StyledLogoDivider feVertical={true} feColor="secondary" feSpacing="lg" />
      <Text feColor="white" feFontSize="lg">
        {envTitle[env]}
      </Text>
    </>
  )
}
