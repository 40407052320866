import { Action } from 'store/types'
import { Middleware } from '@reduxjs/toolkit'

/**
 * Middleware to handle batched actions in a Redux store.
 * From https://www.npmjs.com/package/redux-batched-actions
 * This middleware intercepts actions and checks if they contain a `meta.batch` property.
 * If the `meta.batch` property is present, it assumes the action's payload is an array of actions
 * and dispatches each action individually. If the `meta.batch` property is not present, it simply
 * passes the action to the next middleware or reducer.
 *
 * @template T - The type of the action's `type` property.
 * @template P - The type of the action's `payload` property.
 * @returns {Middleware} The middleware function to be applied to the Redux store.
 *
 * @example
 * // Example action with batch meta
 * const batchedAction = {
 *   type: 'BATCH_ACTION',
 *   payload: [
 *     { type: 'ACTION_ONE', payload: { ... } },
 *     { type: 'ACTION_TWO', payload: {  ...  } }
 *   ],
 *   meta: { batch: true }
 * };
 *
 * // Applying the middleware
 * const store = createStore(
 *   rootReducer,
 *   applyMiddleware(batchDispatchMiddleware())
 * );
 *
 * // Dispatching the batched action
 * store.dispatch(batchedAction);
 */
const batchDispatchMiddleware = <T extends string, P>(): Middleware => {
  return (store) => (next) => (action: unknown) => {
    const darwinAction = action as Action<T, P>
    if (!darwinAction.meta?.batch) return next(action)
    return (darwinAction.payload as Action<T, P>[]).map((action) => store.dispatch(action))
  }
}

// const enableBatching = <S, T extends string, P>(
//   reduce: (state: S, action: Action<T>) => S
// ): ((state: S, action: Action<T>) => S) => {
//   return function batchingReducer(state: S, action: Action<T>): S {
//     if (action.meta && action.meta.batch) {
//       return (action.payload as Action<T, P>[]).reduce(batchingReducer, state) as S
//     }
//     return reduce(state, action)
//   }
// }

export { batchDispatchMiddleware /*enableBatching*/ }
