import { DetailedAssetEvidence } from '@/models/reportGenerator/types'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { dataTestId } from '@/tests/testid'
import { Image, StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import { FC } from 'react'
import { extractFileNameAndExtension } from '@/shared/utils'

const styles = StyleSheet.create(detailedAssetHealth as Styles)
interface ReportAssetEvidenceProps {
  evidence: DetailedAssetEvidence
}

const ReportAssetEvidence: FC<ReportAssetEvidenceProps> = ({ evidence }) => {
  if (extractFileNameAndExtension(evidence.fileName).fileExtension === 'gif') return null // GIFs cannot be rendered in the PDF report

  return (
    <View key={evidence.fileID} style={styles.evidenceWrap}>
      <Image style={styles.evidenceImage} src={evidence.fileURL} data-testid={dataTestId.reportAssetEvidenceImage} />
      <View style={styles.faultDetailsWrap}>
        <Text style={styles.evidenceText}>{evidence.fileName}</Text>
        <Text style={styles.evidenceText}>{formatDate(evidence.fileCreatedAt, DateFormats.ISO8601Date)}</Text>
      </View>
      <View>
        <Text style={styles.evidenceText}>Comment: {evidence.comment}</Text>
      </View>
    </View>
  )
}

export default ReportAssetEvidence
