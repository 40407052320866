import { switchHandleComponentsIds } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { redirectDataBySidSelector } from '@/store/selectors/redirectDataSelector'
import { useTypedSelector } from '@/store/store'
import { MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table'
import { useParams } from 'react-router'

type HandleTableDataChangeProps<T extends MRT_RowData, V> = {
  table: MRT_TableInstance<T>
  setCurrentSelectedRow?: (value: React.SetStateAction<T | undefined>) => void
  tableData?: V
}

const useHandleTableDataChange = <T extends MRT_RowData, V>({
  table,
  tableData,
  setCurrentSelectedRow,
}: HandleTableDataChangeProps<T, V>) => {
  const { sid } = useParams()
  const redirectData = useTypedSelector((state) => redirectDataBySidSelector(state, sid))
  const { tableStateChangeSwitchCurrentState: isSwitchOn, isTableStateAutoSwitched } = useTypedSelector((state) =>
    headerActionPanelConfigSelector(state)
  )

  const tableState = table.getState()

  const tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  const tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])
  const tablePagination = useDeepCompareMemo(() => tableState.pagination, [tableState])

  const sortedRows = useDeepCompareMemo(
    () => table.getSortedRowModel().rows as MRT_Row<T>[],
    [tableData, tableSorting, tableColumnFilters]
  )

  useDeepCompareEffect(() => {
    const tableContainer = table.refs.tableContainerRef.current
    if (tableContainer && sortedRows.length > 0) {
      if (!isSwitchOn) {
        const firstRow = tableContainer.querySelector('tbody tr:first-of-type')
        if (firstRow) {
          if (sortedRows.length > 0 && !redirectData?.tableSelectedRowIndex) {
            const rowElement = firstRow as HTMLTableRowElement
            setCurrentSelectedRow && setCurrentSelectedRow(sortedRows[0].original)
            rowElement.focus()
          } else if (sortedRows.length > 0 && redirectData?.tableSelectedRowIndex) {
            const rowElement = tableContainer.querySelector(
              `tbody tr:nth-of-type(${redirectData.tableSelectedRowIndex})`
            ) as HTMLTableRowElement
            if (rowElement) {
              rowElement.focus()
            }
          }
        }
      } else if (isSwitchOn && !isTableStateAutoSwitched) {
        const findRowIndex = (condition: (row: MRT_Row<T>) => boolean) => sortedRows.findIndex(condition)
        const findLastRowIndex = (condition: (row: MRT_Row<T>) => boolean) => sortedRows.findLastIndex(condition)

        let indexToScroll = findRowIndex((row) => row.original.isHandled === true || row.original.isClosed === true)
        let rowToScrollId = switchHandleComponentsIds.switchOnItem

        if (indexToScroll < 0) {
          indexToScroll = findLastRowIndex((row) => row.original.isHandled === false || row.original.isClosed === false)
          rowToScrollId = switchHandleComponentsIds.switchOffItem
        }
        if (indexToScroll !== -1) {
          setTimeout(
            (indexToScroll) => {
              table.setPageIndex(Math.ceil(indexToScroll / tablePagination.pageSize) - 1)
              setCurrentSelectedRow && setCurrentSelectedRow(sortedRows[indexToScroll].original)
              setTimeout(
                (indexToScroll) => {
                  const temp = table.refs.tableContainerRef.current?.querySelector(
                    `tr:has(#${rowToScrollId}-${indexToScroll})`
                  )
                  table.refs.tableContainerRef.current!.scrollTop = (temp as HTMLElement).offsetTop
                },
                500,
                indexToScroll
              )
            },
            500,
            indexToScroll
          )
        }
      }
    }
  }, [sortedRows, isSwitchOn])
}

export default useHandleTableDataChange
