import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
  InternalAxiosRequestConfig,
} from 'axios'
import {
  apiErrorResponseMapping,
  apiSuccessResponseMapping,
  securityRequestTransform,
  securityResponseTransform,
  //getBaseClientUrl,
} from './baseClientUtils'
import { IBaseClient } from './IBaseClient'
import { PATHS } from './types'
import { stopLoading } from '@/store/loading/action'
import { store } from '@/store/store'

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
}

export type AxiosInterceptors = {
  request: AxiosInterceptorManager<AxiosRequestConfig>
  response: AxiosInterceptorManager<AxiosResponse<Record<string, unknown>>>
}

export abstract class BaseClient implements IBaseClient {
  private instance: AxiosInstance | null = null
  private cancelTokenSource: { [key: string]: { cancel: Canceler } } = {}

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.init()
  }

  init(): AxiosInstance {
    const http = axios.create({
      headers,
      transformRequest: [
        securityRequestTransform,
        ...(Array.isArray(axios.defaults.transformRequest) ? axios.defaults.transformRequest : []),
      ],
      transformResponse: [securityResponseTransform],
    })

    this.addInterceptors(http)

    this.instance = http
    return http
  }

  private createCancelToken(url: PATHS): {
    config: AxiosRequestConfig
    cancel: Canceler
  } {
    const cancelToken = axios.CancelToken.source()
    this.cancelTokenSource[url] = cancelToken

    return {
      config: {
        cancelToken: cancelToken.token,
      },
      cancel: cancelToken.cancel,
    }
  }

  get<T = Record<string, unknown>, R = AxiosResponse<T>>(url: PATHS, config?: AxiosRequestConfig): Promise<R> {
    const { config: requestConfig } = this.createCancelToken(url)
    return this.http.get<T, R>(url, { ...config, ...requestConfig })
  }

  post<T = Record<string, unknown>, R = AxiosResponse<T>>(
    url: PATHS,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const { config: requestConfig } = this.createCancelToken(url)
    return this.http.post<T, R>(url, data, { ...config, ...requestConfig })
  }

  put<T = Record<string, unknown>, R = AxiosResponse<T>>(
    url: PATHS,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const { config: requestConfig } = this.createCancelToken(url)
    return this.http.put<T, R>(url, data, { ...config, ...requestConfig })
  }

  patch<T = Record<string, unknown>, R = AxiosResponse<T>>(
    url: PATHS,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    const { config: requestConfig } = this.createCancelToken(url)
    return this.http.patch<T, R>(url, data, { ...config, ...requestConfig })
  }

  delete<T = Record<string, unknown>, R = AxiosResponse<T>>(url: PATHS, config?: AxiosRequestConfig): Promise<R> {
    const { config: requestConfig } = this.createCancelToken(url)
    return this.http.delete<T, R>(url, { ...config, ...requestConfig })
  }

  cancelRequest(url: PATHS): void {
    if (this.cancelTokenSource[url]) {
      this.cancelTokenSource[url].cancel('Operation canceled by the user.')
      delete this.cancelTokenSource[url]
    }
  }

  protected addInterceptors(axiosInstance: AxiosInstance): void {
    axiosInstance.interceptors.request.use(
      (value: InternalAxiosRequestConfig) => {
        return value
      },
      (error) => {
        store.dispatch(stopLoading())
        return Promise.reject(error)
      }
    )
    axiosInstance.interceptors.response.use(
      (response) => {
        return apiSuccessResponseMapping(response)
      },
      (error) => {
        store.dispatch(stopLoading())
        return apiErrorResponseMapping(error)
      }
    )
  }

  protected setBaseUrl(baseURL: string): void {
    this.http.defaults.baseURL = baseURL
  }
}
