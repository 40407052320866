import { FC } from 'react'
import { StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'

const styles = StyleSheet.create(detailedAssetHealth as Styles)
interface ReportFieldProps {
  label: string
  value: string | null
}

const ReportField: FC<ReportFieldProps> = ({ label, value }) => {
  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  )
}

export default ReportField
