import { FC } from 'react'
import { ReportAssetHealthDetails } from '@/models/reportGenerator/types'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import ReportField from '@/modules/report-generator/components/reports/detailedAssetHealth/reportField'
import ReportStatusField from '@/modules/report-generator/components/reports/detailedAssetHealth/reportStatusField'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'

const styles = StyleSheet.create(detailedAssetHealth as Styles)
interface ReportAssetDetailsProps {
  assetDetails: Omit<ReportAssetHealthDetails, 'faults' | 'statusHistory' | 'lastMeasurements'>
}

const ReportAssetDetails: FC<ReportAssetDetailsProps> = ({ assetDetails }) => {
  return (
    <>
      <Text style={styles.heading}>Detailed Asset Health: {assetDetails.assetName}</Text>
      <View style={styles.sectionContainer}>
        <View style={styles.column}>
          <ReportField
            label="Location"
            value={` ${assetDetails.assetLocation.siteName} > ${assetDetails.assetLocation.parentName}`}
          />
          <ReportField label="Description" value={assetDetails.assetDescription} />
        </View>

        <View style={styles.column}>
          <ReportField label="Asset Type" value={assetDetails.assetType} />
          <ReportField label="Criticality" value={assetDetails.assetCriticality} />
          <ReportStatusField label="Status" status={assetDetails.assetStatus} />
        </View>

        <View style={styles.column}>
          <ReportField
            label="Current condition reported"
            value={formatDate(assetDetails.currentConditionReported, DateFormats.ISO8601Date)}
          />
          <ReportField
            label="Last collection date"
            value={formatDate(assetDetails.lastCollectionDate, DateFormats.ISO8601Date)}
          />
          <ReportField label="Reported by" value={assetDetails.reporterName} />
        </View>
      </View>
    </>
  )
}

export default ReportAssetDetails
