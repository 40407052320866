import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useOpenRecommendationsContext } from '@/contexts/openRecommendations'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { OpenRecommendationData } from '@/models/openRecommendations/types'
import { useParams } from 'react-router'
import { getOpenRecommendations } from '@/api/paths/openRecommendations'

const useGetOpenRecommendations = () => {
  const { sid } = useParams()

  const { setOpenRecommendations } = useOpenRecommendationsContext()

  const throwError = useAsyncThrow()

  return useApi(async () =>
    getOpenRecommendations(
      {
        onSuccess: async (result: OpenRecommendationData) => {
          setOpenRecommendations(result)
        },
        onError: async (error) => {
          throwError(new DarwinError(error))
        },
      },
      {
        sid,
      }
    )
  )
}

export default useGetOpenRecommendations
