import {
  ChannelFaultEnum,
  ChannelInterface,
  ChannelStatusEnum,
  DeviceFaultEnum,
  DeviceInterface,
  DeviceModel,
  DeviceModelEnum,
  DeviceStatusEnum,
} from '@/models/devicesAndSensors/types'
import { deviceNumberFormatter } from '@/modules/devicesSensors/utils/constants'
import {
  channelFaultText,
  channelStatusString,
  deviceFaultText,
  deviceStatusString,
} from '@/modules/devicesSensors/utils/constants'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { capitalizeFirstLetter, formatProp, roundTo } from '@/shared/utils'
import { MRT_ColumnFiltersState } from 'material-react-table'

type DateFilter = {
  dateFilterType: string
  endDate: Date | undefined
  startDate: Date | undefined
}

const filtersTypes = ['name', 'status', 'lastCommunicationDate']

/**
 * Formats the given column filters into a record with string values.
 *
 * @param {MRT_ColumnFiltersState} filters - The state of the column filters to format.
 * @returns {Record<string, string>} A record where the keys are the filter ids and the values are the formatted filter values.
 *
 * The function initializes a result object with default values for 'name', 'status', and 'lastCommunicationDate'.
 * It then iterates over the provided filters and updates the result object based on the filter values:
 * - If the filter value is an array, it concatenates the capitalized values into a single string separated by dots.
 * - If the filter id is 'lastCommunicationDate', it formats the start and end dates into a single string.
 * - Otherwise, it converts the filter value to a string.
 *
 * @example
 * const filters = [
 *   { id: 'name', value: ['sensor1', 'sensor2'] },
 *   { id: 'status', value: 'active' },
 *   { id: 'lastCommunicationDate', value: { startDate: new Date(), endDate: new Date() } }
 * ];
 * const formattedFilters = formatFilters(filters);
 * // formattedFilters = {
 * //   name: 'Sensor1.Sensor2.',
 * //   status: 'active',
 * //   lastCommunicationDate: 'Start Date: [start date].End Date: [end date]'
 * // }
 */
const formatFilters = (filters: MRT_ColumnFiltersState): Record<string, string> => {
  const result: Record<string, string> = {
    name: 'none',
    status: 'none',
    lastCommunicationDate: 'none',
  }

  filters.forEach((filter) => {
    if (filter.value && filtersTypes.includes(filter.id)) {
      if (Array.isArray(filter.value)) {
        result[filter.id] = filter.value.reduce((result, value) => (result += capitalizeFirstLetter(value) + '.'), '')
      } else if (filter.id === 'lastCommunicationDate') {
        const dateFilterObject = filter.value as DateFilter
        const startDate = `Start Date: ${dateFilterObject.startDate?.toString() ?? 'none'}`
        const endDate = `End Date: ${dateFilterObject.endDate?.toString() ?? 'none'}`
        result[filter.id] = startDate.concat('.', endDate)
      } else {
        result[filter.id] = filter.value.toString()
      }
    }
  })

  return result
}

/**
 * Formats the channel status and faults into a readable string.
 *
 * @param {ChannelStatusEnum} status - The status of the channel.
 * @param {ChannelInterface['faults']} faults - The list of faults associated with the channel.
 * @returns {string} A formatted string representing the channel status and its faults.
 */
const formatStatusAndFaults = <
  S extends ChannelStatusEnum | DeviceStatusEnum,
  F extends ChannelFaultEnum | DeviceFaultEnum,
>(
  status: S,
  faults: { fault: F }[],
  statusStringMap: Record<S, string>,
  faultTextMap: Record<F, string>
): string => {
  let text = `${statusStringMap[status]}`

  if (status !== 'normal') {
    text = `${text} ( ${faults
      .sort((f1, f2) => f1.fault.localeCompare(f2.fault))
      .map((v) => faultTextMap[v.fault])
      .join('|')} )`
  }

  return text
}

/**
 * Formats the channel status and faults.
 *
 * This function takes a channel status and a list of faults, and formats them
 * using the provided formatting functions.
 *
 * @param {ChannelStatusEnum} status - The status of the channel.
 * @param {ChannelInterface['faults']} faults - The list of faults associated with the channel.
 * @returns {FormattedStatusAndFaults} The formatted status and faults.
 */
const formatChannelStatusAndFaults = (status: ChannelStatusEnum, faults: ChannelInterface['faults']) => {
  return formatStatusAndFaults(status, faults, channelStatusString, channelFaultText)
}

/**
 * Formats the device status and faults.
 *
 * @param {DeviceStatusEnum} status - The status of the device.
 * @param {DeviceInterface['faults']} faults - The faults associated with the device.
 * @returns {ReturnType<typeof formatStatusAndFaults>} The formatted status and faults.
 */
const formatDeviceStatusAndFaults = (status: DeviceStatusEnum, faults: DeviceInterface['faults']) => {
  return formatStatusAndFaults(status, faults, deviceStatusString, deviceFaultText)
}

/**
 * Formats the internal temperature value with the specified unit.
 *
 * @param internalTemp - The internal temperature value as a string or null.
 * @param unit - The unit of the temperature (e.g., "C" for Celsius, "F" for Fahrenheit).
 * @returns The formatted temperature string with the unit, or '-' if the internal temperature is null.
 */
const formatInternalTemp = (internalTemp: string | null, unit: string): string => {
  if (internalTemp !== null)
    return `${formatProp(internalTemp, (prop) => roundTempValue(parseFloat(prop)))} ${formatProp(unit, (prop) => prop)}`
  else return '-'
}

/**
 * Formats the used buffer value with the specified unit.
 *
 * @param {string | null} usedBuffer - The used buffer value to format. If null, returns '-'.
 * @param {string} unit - The unit to append to the formatted used buffer value.
 * @returns {string} The formatted used buffer value with the unit, or '-' if the used buffer is null.
 */
const formatUsedBuffer = (usedBuffer: string | null, unit: string): string => {
  if (usedBuffer !== null)
    return `${formatProp(usedBuffer, (prop) => roundTo(parseFloat(prop), 1).toString())} ${formatProp(unit, (prop) => prop)}`
  else return '-'
}

/**
 * Exports devices and sensors data to a CSV formatted string.
 *
 * @param {DeviceInterface[]} data - An array of device objects containing sensor data.
 * @param {string} customerName - The name of the customer for whom the data is being exported.
 * @param {MRT_ColumnFiltersState} filters - The filters applied to the data.
 * @returns {string} - A CSV formatted string representing the devices and sensors data.
 */
const exportDevicesAndSensorsDataToCSV = (
  data: DeviceInterface[],
  customerName: string,
  filters: MRT_ColumnFiltersState
): string => {
  const formattedFilters = formatFilters(filters)

  return [
    ['sep=,'],
    ['Site Name:', customerName].join(','),
    ['Dashboard:', 'Device & Sensor Management'].join(','),
    ['Export date:', formatDate(new Date(), DateFormats.AmericanDateTimeFormat)].join(','),
    [],
    [],
    [],
    [
      'Filters Applied:',
      '-',
      formattedFilters.name,
      '-',
      formattedFilters.status,
      formattedFilters.lastCommunicationDate,
      '-',
      '-',
      '-',
      '-',
      '-',
      '-',
    ].join(','),
    [
      'Device / Channel number',
      'Type',
      'Name',
      'Device',
      'Status',
      'Last Communication',
      'Device Firmware Version',
      'Device Internal Temperature',
      'Device used Buffer (MB)',
      'Sensor Voltage',
      'Sensor functional Location',
      'Sensor asset',
    ].join(','),
    data
      .map((device) => {
        const digitalChannelsData = device.channels
          .filter((c) => c.type === 'digital')
          .toSorted((a, b) => a.number - b.number) //sort alphabetically by type
          .map((c) => [
            `\t${deviceNumberFormatter.format(c.number)}`,
            'Sensor',
            c.name,
            device.deviceName,
            formatChannelStatusAndFaults(c.status, c.faults),
            (c.lastCommunicationTime && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
            '-',
            '-',
            '-',
            c.voltage || '-',
            (c.uniquePlaces &&
              c.uniquePlaces
                .map((item) => (item.functionalLocationName ? item.functionalLocationName : '-'))
                .join('; ')) ||
              '-',
            (c.uniquePlaces && c.uniquePlaces.map((item) => (item.asset.name ? item.asset.name : '-')).join('; ')) ||
              '-',
          ])
          .join('\n')

        const analogChannelsData = device.channels
          .filter((c) => c.type === 'analog')
          .toSorted((a, b) => a.number - b.number) //sort alphabetically by type
          .map((c) => [
            `\t${deviceNumberFormatter.format(c.number)}`,
            'Sensor',
            c.name,
            device.deviceName,
            formatChannelStatusAndFaults(c.status, c.faults),
            (c.lastCommunicationTime && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
            '-',
            '-',
            '-',
            c.voltage || '-',
            (c.uniquePlaces &&
              c.uniquePlaces
                .map((item) => (item.functionalLocationName ? item.functionalLocationName : '-'))
                .join('; ')) ||
              '-',
            (c.uniquePlaces && c.uniquePlaces.map((item) => (item.asset.name ? item.asset.name : '-')).join('; ')) ||
              '-',
          ])
          .join('\n')

        const deviceData = [
          `\t${deviceNumberFormatter.format(device.deviceNumber)}`,
          'Device',
          device.deviceName,
          '-',
          formatDeviceStatusAndFaults(device.status, device.faults),
          (device.lastCommunicationDate && formatDate(device.lastCommunicationDate, DateFormats.LongDateFormat)) || '-',
          device.firmwareVersion || '-',
          formatInternalTemp(device.internalTemp.value, device.internalTemp.unit),
          formatUsedBuffer(device.usedBufferObject.value, device.usedBufferObject.unit),
          '-',
          '-',
          '-',
        ].join()

        const returnData = [deviceData]

        analogChannelsData.length > 0 && returnData.push(analogChannelsData)
        digitalChannelsData.length > 0 && returnData.push(digitalChannelsData)

        return returnData.join('\n')
      })
      .join('\n'),
  ].join('\n')
}

/**
 * Transforms a given device model enum value to a corresponding device model string.
 *
 * @param {DeviceModelEnum} deviceModel - The device model enum value to transform.
 * @returns {DeviceModel} The transformed device model string.
 */
const transformDeviceModelStatus = (deviceModel: DeviceModelEnum): DeviceModel => {
  let value = 'invalid'

  if (deviceModel === 'IMxSixteenPlus' || deviceModel === 'IMxSixteenW') {
    value = 'IMX-16'
  } else if (deviceModel === 'IMxEight') {
    value = 'IMX-8'
  }

  return value as DeviceModel
}

/**
 * Rounds a voltage value to one decimal place and returns it as a string.
 *
 * @param {number} value - The voltage value to be rounded.
 * @returns {string} The rounded voltage value as a string.
 */
const roundVoltageValue = (value: number): string => value.toFixed(1)
/**
 * Rounds the given temperature value to the nearest integer and converts it to a string.
 *
 * @param value - The temperature value to be rounded.
 * @returns The rounded temperature value as a string.
 */
const roundTempValue = (value: number): string => Math.trunc(value).toString()

/**
 * Checks if there is any fault of type `NoMeasurementEver` in the provided faults array,
 * and ensures there is no fault of type `CableFault`.
 *
 * @param {ChannelInterface['faults']} faults - An array of fault objects to check.
 * @returns {boolean} - Returns `true` if there is at least one fault with the type `NoMeasurementEver`
 *                      and no faults of type `CableFault`, otherwise returns `false`.
 */
const hasNoMeasurementEverData = (faults: ChannelInterface['faults']): boolean => {
  const hasNoMeasurementEver = faults.some((f) => f.fault === ChannelFaultEnum.NoMeasurementEver)
  const hasBrokenConnection = faults.some((f) => f.fault === ChannelFaultEnum.CableFault)

  return hasNoMeasurementEver && !hasBrokenConnection
}

export {
  roundTempValue,
  roundVoltageValue,
  hasNoMeasurementEverData,
  exportDevicesAndSensorsDataToCSV,
  transformDeviceModelStatus,
  formatInternalTemp,
  formatUsedBuffer,
}
