import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectSitesPermissions = (state: RootState) => state.sitesPermissions
const _selectSitePermissionBySid = (_: RootState, sid: string | undefined) => sid

const sitePermissionsBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return sid && sitesPermissions[sid]
  }
)

const copyAssetPathPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.analysis_backlog_copy_asset_path)
  }
)

const showHandledByPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.analysis_backlog_show_handled)
  }
)

const exportTableByPermissionBySidSelector = createSelector(
  _selectSitesPermissions,
  _selectSitePermissionBySid,
  (sitesPermissions, sid) => {
    return !!(sid && sitesPermissions[sid]?.analysis_backlog_export_table)
  }
)

export {
  sitePermissionsBySidSelector,
  copyAssetPathPermissionBySidSelector,
  showHandledByPermissionBySidSelector,
  exportTableByPermissionBySidSelector,
}
