import routesConfig from '@/app/routes/routesConfig'
import { initDatadog, setDatadogUser } from '@/app/datadogConfig'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { batchActions } from '@/store/batch/actions'
import {
  clearModuleHeaderActionPanelData,
  clearModuleHeaderConfigData,
  clearModuleHeaderCSVData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch } from '@/store/store'
import { useEffect } from 'react'
import { createBrowserRouter, NavigationType, RouterProvider } from 'react-router'

/**
 * RouterConsumer is a component that sets up the application's routing
 * using React Router. It initializes the Datadog monitoring service
 * and clears specific module header data in the Redux store when
 * navigation actions occur.
 *
 * This component leverages the React Router's createBrowserRouter
 * method to configure routes based on a provided routes configuration.
 * It also subscribes to navigation events to manage state appropriately
 * whenever the user navigates back in history.
 *
 * The following actions are performed:
 * - Initializes Datadog for application performance monitoring.
 * - Sets the user information in Datadog.
 * - Clears module header data in the Redux store when navigating
 *   back in history.
 *
 * @returns {JSX.Element} A RouterProvider component that provides
 * the router instance to the application.
 */

const RouterConsumer = () => {
  const routerConsumerDispatch = useAppDispatch()
  const router = createBrowserRouter(routesConfig(), {
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
      // v7_skipActionErrorRevalidation: true,
    },
  })

  useEffect(() => {
    const unsubscribe = router.subscribe((state) => {
      if (state.historyAction === NavigationType.Pop) {
        routerConsumerDispatch(
          batchActions([clearModuleHeaderActionPanelData(), clearModuleHeaderConfigData(), clearModuleHeaderCSVData()])
        )
      }
    })

    return () => unsubscribe()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffectOnce(() => {
    initDatadog()
    setDatadogUser()
  })

  return (
    <>
      <RouterProvider router={router} />
      {/* {children} */}
    </>
  )
}

export default RouterConsumer
