import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { useMemo } from 'react'

const useGetCharactersLength = () => {
  const { width } = useWindowDimensions()

  const characterLengths = useMemo(() => {
    if (width <= 1200) {
      return {
        number: 3,
        name: 7,
        lastCommunicationDate: 14,
        assetName: 10,
      }
    } else if (width <= 1440) {
      return {
        number: 3,
        name: 17,
        lastCommunicationDate: 24,
        assetName: 15,
      }
    } else if (width <= 1900) {
      return {
        number: 3,
        name: 17,
        lastCommunicationDate: 24,
        assetName: 18,
      }
    } else {
      return {
        number: 12,
        name: 50,
        lastCommunicationDate: 24,
        assetName: 26,
      }
    }
  }, [width])

  return characterLengths
}
export default useGetCharactersLength
