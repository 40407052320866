import { useAnalysisBacklogContext } from '@/contexts/analysisBacklog'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { InitialAsset } from '@/models/analysisBacklog/types'
import useResetTableFilters from '@/shared/components/tableComponents/hooks/useResetTableFilters'
import { calculateInfoPanelData, exportAnalysisBacklogDataToCsv } from '@/modules/analysisBacklog/utils/index'
import ModuleHeader from '@/shared/components/ModuleHeader'
import { HeaderInfoPanelIconData } from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderCSVData,
  setModuleHeaderConfigData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { MRT_TableInstance } from 'material-react-table'
import { FC } from 'react'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { currentCustomerSelector } from '@/store/selectors/selectedCustomerSelector'
import { batchActions } from '@/store/batch/actions'

interface AnalysisBacklogHeaderProps {
  table: MRT_TableInstance<InitialAsset>
}

const AnalysisBacklogHeader: FC<AnalysisBacklogHeaderProps> = ({ table }) => {
  const analysisBacklogHeaderDispatch = useAppDispatch()

  const { fetchDataStatus } = useFetchDataContext()

  const selectedCustomer = useTypedSelector((state) => currentCustomerSelector(state))

  const { tableStateChangeSwitchCurrentState: showHandled } = useTypedSelector((state) =>
    headerActionPanelConfigSelector(state)
  )

  const { analysisBacklogData } = useAnalysisBacklogContext()

  const { columnFilters, sorting, pagination, showSkeletons } = table.getState()

  const tableRows = useDeepCompareMemo(
    () => table.getSortedRowModel().rows,
    [analysisBacklogData, sorting, pagination, columnFilters, fetchDataStatus, showSkeletons, showHandled]
  )

  const inLoadingDataMode = useDeepCompareMemo(
    () => tableRows.length > 0 && tableRows.every((row) => row.original.belongsTo === null),
    [tableRows]
  )

  const isTableFiltered = useDeepCompareMemo(
    () => columnFilters.length > 0 || sorting.length > 0,
    [columnFilters, sorting]
  )

  const infoPanelData = useDeepCompareMemo<HeaderInfoPanelIconData[]>(() => {
    let result: HeaderInfoPanelIconData[] = []

    if (!inLoadingDataMode && fetchDataStatus !== 'loading') {
      result = calculateInfoPanelData(table)
    }
    return result
  }, [inLoadingDataMode, showHandled, fetchDataStatus, columnFilters])

  const resetTableFilters = useResetTableFilters<InitialAsset>(table)

  useDeepCompareEffect(() => {
    analysisBacklogHeaderDispatch(
      setModuleHeaderConfigData({
        infoPanelElement: undefined,
        infoPanelData,
      })
    )
  }, [infoPanelData])

  useDeepCompareEffect(() => {
    analysisBacklogHeaderDispatch(
      batchActions([
        setModuleHeaderConfigData({
          showHeader: true,
          showInfoPanel: true,
          showActionPanel: true,
          showSubtitle: false,
          title: 'Analysis Backlog',
        }),
        setModuleHeaderActionPanelData({
          withLoader: true,
          withClearFiltersButton: true,
          isClearFiltersButtonDisabled: !isTableFiltered,
          clearFiltersButtonFn: resetTableFilters,
          withTableStateChangeSwitch: true,
          tableStateChangeSwitchLabel: 'Show handled',
          withRefreshButton: true,
          withCSVDownloadButton: true,
          withAsyncIndicator: false,
        }),
      ])
    )
  }, [table, fetchDataStatus, isTableFiltered])

  useDeepCompareEffect(() => {
    if (!inLoadingDataMode && selectedCustomer) {
      analysisBacklogHeaderDispatch(
        setModuleHeaderCSVData({
          csvData: exportAnalysisBacklogDataToCsv(
            tableRows.map((row) => row.original),
            selectedCustomer.name,
            columnFilters
          ),
          moduleName: 'Analysis Backlog',
        })
      )
    }
  }, [tableRows, selectedCustomer, columnFilters])

  return <ModuleHeader />
}

export default AnalysisBacklogHeader
