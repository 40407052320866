import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { DeviceInterface } from '@/models/devicesAndSensors/types'
import useDevicesColumnsDefinition from '@/modules/devicesSensors/columns/useDevicesColumnsDefinition'
import ChannelsPane from '@/modules/devicesSensors/components/channelsPane'
import {
  deviceTableBodyCellProps,
  deviceTableBodyRowProps,
  deviceTableContainerProps,
  deviceTableDetailsPanelProps,
  deviceTableExpandButtonProps,
  deviceTableHeadCellProps,
  deviceTableHeaderProps,
  deviceTablePaperProps,
} from '@/modules/devicesSensors/styled/tableSx'
import useTableIcons from '@/shared/components/tableComponents/hooks/useTableIcons'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useEffectOnce } from '@/shared/hooks/useEffectOnce'
import { deleteRedirectData } from '@/store/redirectData/actions'
import { redirectDataBySidSelector } from '@/store/selectors/redirectDataSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { MRT_Row, useMaterialReactTable } from 'material-react-table'
import { useParams } from 'react-router'

const useDevicesTable = () => {
  let sortedRows: MRT_Row<DeviceInterface>[] = []
  const { sid } = useParams()

  const { devicesAndSensorsData, setDevicesAndSensorsSelectedItemsData, devicesAndSensorsSelectedItemsData } =
    useDevicesAndSensorsContext()

  const previousPageData = useTypedSelector((state) => redirectDataBySidSelector(state, sid))

  const deviceSensorsTableDispatch = useAppDispatch()

  const { fetchDataStatus } = useFetchDataContext()

  useEffectOnce(() => {
    if (previousPageData) {
      setTimeout(() => {
        deviceSensorsTableDispatch(deleteRedirectData(sid as string))
      }, 500)
    }
  })

  const { device } = devicesAndSensorsSelectedItemsData

  const columns = useDevicesColumnsDefinition()

  const onExpandButtonClick = useDeepCompareCallback(
    (row: DeviceInterface) => {
      setDevicesAndSensorsSelectedItemsData({
        ...devicesAndSensorsSelectedItemsData,
        device: row,
        channel: undefined,
        // channelIndex: 0,
      })
    },
    [devicesAndSensorsSelectedItemsData]
  )

  const onDeviceTableRowClick = useDeepCompareCallback(
    (row: MRT_Row<DeviceInterface>) => {
      row.toggleExpanded()
      setDevicesAndSensorsSelectedItemsData({
        ...devicesAndSensorsSelectedItemsData,
        device: row.original,
        channel: undefined,
        //channelIndex: 0,
      })
    },
    [devicesAndSensorsSelectedItemsData]
  )

  const deviceTableIcons = useTableIcons()

  const devicesTable = useMaterialReactTable({
    columns,
    data: devicesAndSensorsData,
    enableRowVirtualization: true,
    layoutMode: 'grid',
    rowVirtualizerOptions: {
      overscan: 5,
      estimateSize: () => 50,
    },
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableMultiSort: true,
    enableKeyboardShortcuts: false,
    filterFromLeafRows: true,
    maxMultiSortColCount: 3,
    muiSkeletonProps: {
      height: 35,
    },
    isMultiSortEvent: () => true,
    initialState: { columnFilters: previousPageData?.columnFilters || [] },
    state: {
      showSkeletons: devicesAndSensorsData.length === 0 && fetchDataStatus === 'loading',
    },
    renderEmptyRowsFallback: () => <div />,
    muiTablePaperProps: deviceTablePaperProps,
    muiTableHeadProps: deviceTableHeaderProps,
    muiDetailPanelProps: deviceTableDetailsPanelProps,
    muiTableContainerProps: ({ table }) => deviceTableContainerProps(table),
    defaultColumn: {
      muiTableBodyCellProps: deviceTableBodyCellProps,
      muiTableHeadCellProps: () => deviceTableHeadCellProps(fetchDataStatus !== 'loading'),
    },
    muiExpandButtonProps: ({ row }) => {
      return {
        ...deviceTableExpandButtonProps,
        onClick: () => onExpandButtonClick(row.original),
      }
    },
    muiTableBodyRowProps: ({ row, isDetailPanel }) => {
      const rowIndex: number = sortedRows.findIndex((item) => item.id === row.id)
      const nextKeydownIndex: number = sortedRows.findIndex((item) => item.original.deviceID === device?.deviceID)
      const isSelected = device?.deviceID === row.original.deviceID
      return {
        ...deviceTableBodyRowProps(!!isDetailPanel, rowIndex, isSelected),
        tabIndex: 0,
        onClick: () => onDeviceTableRowClick(row),
        onKeyDown: (e) => onDeviceTableRowKeyDown(e, row, nextKeydownIndex),
      }
    },
    columnFilterDisplayMode: 'custom',
    enableExpanding: true,
    positionExpandColumn: 'last',
    renderDetailPanel: ({ row }) => {
      return row.original.channels && <ChannelsPane channels={row.original.channels} device={row.original} />
    },
    displayColumnDefOptions: {
      'mrt-row-expand': {
        size: 40,
        grow: false,
      },
    },
    icons: deviceTableIcons,
  })

  const tableState = devicesTable.getState()

  const tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  const tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  sortedRows = useDeepCompareMemo(
    () => devicesTable.getSortedRowModel().rows as MRT_Row<DeviceInterface>[],
    [devicesAndSensorsData, tableSorting, tableColumnFilters]
  )

  /**
   * Logic explanation:
   * If not rows remove the selected devices and sensors data
   * If there are rows, but no selected device, select the first one
   * If there are rows and a selected device, but the selected device is not in the sorted rows, select the first one (for filtering case)
   * If there are rows and a selected device, and the selected device is in the sorted rows, keep it
   */
  useDeepCompareEffect(() => {
    const selectedDevice = sortedRows.find(
      (row) => row.original.deviceID === devicesAndSensorsSelectedItemsData.device?.deviceID
    )
    setDevicesAndSensorsSelectedItemsData({
      ...devicesAndSensorsSelectedItemsData,
      device: sortedRows.length > 0 ? selectedDevice?.original || sortedRows[0].original : undefined,
      channel: sortedRows.length > 0 ? devicesAndSensorsSelectedItemsData.channel : undefined,
    })
  }, [sortedRows])

  const onDeviceTableRowKeyDown = useDeepCompareCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, row: MRT_Row<DeviceInterface>, rowIndex: number) => {
      if (event.key === 'Enter' && row.original.channels.length > 0) {
        row.toggleExpanded()
      }
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        if (event.key === 'ArrowUp' && rowIndex === 0) return
        if (event.key === 'ArrowDown' && rowIndex === sortedRows.length - 1) return

        if (event.key === 'ArrowDown') {
          // if (!row.getIsExpanded()) {
          setDevicesAndSensorsSelectedItemsData({
            ...devicesAndSensorsSelectedItemsData,
            device: sortedRows[rowIndex + 1].original,
            channel: undefined,
          })
          //}
        }
        if (event.key === 'ArrowUp') {
          setDevicesAndSensorsSelectedItemsData({
            ...devicesAndSensorsSelectedItemsData,
            device: sortedRows[rowIndex - 1].original,
            channel: undefined,
          })
        }
      }
    },
    [devicesAndSensorsData, tableSorting, tableColumnFilters]
  )

  return devicesTable
}

export default useDevicesTable
