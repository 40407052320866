import React, { FC, ReactNode, useEffect } from 'react'
import { Page, Text, View, Image, StyleSheet, Styles, Font } from '@react-pdf/renderer'
import { pdfStylesObj } from '@/modules/report-generator/utils/pdfStyles'
import { useComponentToImage } from '@/modules/report-generator/hooks/useComponentToImage'
import { dataTestId } from '@/tests/testid'

const pdfStyles = StyleSheet.create(pdfStylesObj as Styles)
Font.registerHyphenationCallback((word) => [word])

interface PdfTemplateProps {
  children: ReactNode // Content to wrap inside the template
}

const PdfTemplate: FC<PdfTemplateProps> = ({ children }) => {
  const { imageUrl: SKFLogo, generateImage } = useComponentToImage(dataTestId.generateReportPDFPageHeaderLogo)

  useEffect(() => {
    generateImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page size="A4" style={pdfStyles.page}>
      {/* Page Header */}
      <View style={pdfStyles.pageHeaderContainer}>
        {SKFLogo && <Image src={SKFLogo as string} style={pdfStyles.pageHeaderLogo} />}
      </View>

      <View style={pdfStyles.divider}></View>

      {/* Dynamic Content */}
      <View style={pdfStyles.innerComponent}>{children}</View>

      {/* Page Number */}
      <Text
        style={pdfStyles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  )
}

export default PdfTemplate
