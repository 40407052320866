import { FC } from 'react'
import { StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'

const styles = StyleSheet.create(detailedAssetHealth as Styles)

interface ReportRecommendedActionFieldProps {
  label: string
  value: string
}

const ReportRecommendedActionField: FC<ReportRecommendedActionFieldProps> = ({ label, value }) => {
  return (
    <View>
      <Text style={styles.recommendationLabel}>{label}</Text>
      <Text style={styles.recommendationDescription}>{value}</Text>
    </View>
  )
}

export default ReportRecommendedActionField
