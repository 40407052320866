import { COLORS } from '@skf-internal/ui-components-react'

export const overviewTableHeadRowProps = {
  sx: {
    ':first-of-type': {
      backgroundColor: COLORS.gray100,
      '& th': {
        padding: '1rem 0 !important',
        ':last-of-type': {
          borderRight: 'none',
        },
      },
    },
    ':nth-of-type(2)': {
      '& th': {
        borderBottom: 'none',
        ':nth-of-type(2), :nth-of-type(4), :nth-of-type(6),:nth-of-type(9)': {
          borderRight: 'none',
        },
      },
    },
    ':nth-of-type(3)': {
      '& th': {
        borderBottom: 'none',
        ':nth-of-type(2), :nth-of-type(4), :nth-of-type(6), :nth-of-type(8),:nth-of-type(11)': {
          borderRight: 'none',
        },
      },
    },
    ':nth-of-type(3) th': {
      ':nth-of-type(2)': {
        fontSize: '0.6rem',
        padding: '0.25rem 0.6rem !important',
        backgroundColor: COLORS.greenBase,
        color: COLORS.white,
        lineHeight: '1rem',
        fontWeight: 700,
        textAlign: 'center',
        verticalAlign: 'middle',
        maxHeight: '1.5rem !important',
        width: '6%',
        marginTop: '0.5rem',
        '& div': {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      ':nth-of-type(3)': {
        fontSize: '0.6rem',
        padding: '0.25rem 0.6rem !important',
        backgroundColor: COLORS.yellowDarker,
        color: COLORS.black,
        lineHeight: '1rem',
        fontWeight: 700,
        textAlign: 'center',
        verticalAlign: 'middle',
        maxHeight: '1.5rem !important',
        justifyContent: 'center',
        alignItems: 'center',
        width: '6%',
        marginTop: '0.5rem',
        '& div': {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
      ':nth-of-type(4)': {
        fontSize: '0.6rem',
        padding: '0.25rem 0.6rem !important',
        backgroundColor: COLORS.redBase,
        color: COLORS.white,
        lineHeight: '1rem',
        fontWeight: 700,
        textAlign: 'center',
        verticalAlign: 'middle',
        maxHeight: '1.5rem !important',
        width: '6%',
        marginTop: '0.5rem',
        '& div': {
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    ':not(:first-of-type)': {
      backgroundColor: COLORS.gray050,
    },
    '& th': {
      textWrap: 'balance',
      '& div': {
        whiteSpace: 'unset',
      },
    },
    boxShadow: 'none',
  },
}

export const overviewTableBodyRowProps = {
  sx: {
    ':hover': {
      backgroundColor: 'transparent',
      '& td': {
        ':after': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
}

export const overviewTablePaperProps = {
  sx: {
    boxShadow: 'none',
  },
}

export const overviewTableProps = {
  sx: {
    // border: `1px solid ${COLORS.gray200}`,
  },
}

export const overviewTableContainerProps = {
  sx: {
    maxHeight: 'calc(100vh - 12rem)',
    backgroundColor: `${COLORS.gray050}`,
  },
}

export const overviewTableBodyCellProps = {
  sx: {
    backgroundColor: `${COLORS.gray050}`,
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '6%',
    '&:focus-visible': {
      outline: 'none',
    },
    ':nth-of-type(1)': {
      '& div': {
        justifyContent: 'flex-start',
        color: `${COLORS.brand}`,
      },
    },
    ':nth-of-type(2), :nth-of-type(3), :nth-of-type(4), :nth-of-type(6), :nth-of-type(7)': {
      '& div>span': {
        textAlign: 'left',
      },
    },
    ':nth-of-type(1), :nth-of-type(3), :nth-of-type(5), :nth-of-type(7), :nth-of-type(9) , :nth-of-type(10)': {
      borderRight: `1px solid ${COLORS.gray200}`,
    },
    ':first-of-type > div > a': {
      color: `${COLORS.brand} !important`,
    },
    ':nth-of-type(2)': {
      backgroundColor: '#e6f3ec',
    },
    ':nth-of-type(3)': {
      backgroundColor: '#fffae8',
    },
    ':nth-of-type(4)': {
      backgroundColor: '#fae6e6',
    },
    ':last-of-type': {
      padding: 0,
      width: '1%',
      opacity: 1,
      '& button': {
        margin: 0,
        padding: 0,
        ':hover': {
          backgroundColor: 'transparent',
        },
        '& span *': {
          backgroundColor: 'transparent',
          '&:focus-visible': {
            outline: 'none',
          },
        },
      },
    },
  },
}

export const overviewTableHeadCellProps = {
  sx: {
    verticalAlign: 'middle',
    fontWeight: 'bold',
    borderRight: `1px solid ${COLORS.gray200}`,
    textWrap: 'balance',
    lineHeight: '1.25rem',
  },
}
