import { DeviceStatusEnum } from '@/models/devicesAndSensors/types'
import { AssetStatusString } from '@/shared/models/types'

const analysisBacklogAssetStatusSortingOrder: AssetStatusString[] = [
  'never-reported',
  'severe',
  'unacceptable',
  'unsatisfactory',
  'acceptable',
  'normal',
] as const

const openRecommendationAssetStatusSortingOrder: AssetStatusString[] = [
  'severe',
  'unacceptable',
  'unsatisfactory',
  'acceptable',
  'normal',
] as const

const devicesAndSensorsStatusSortingOrder: DeviceStatusEnum[] = [
  DeviceStatusEnum.Normal,
  DeviceStatusEnum.NotNormal,
] as const

export {
  analysisBacklogAssetStatusSortingOrder,
  devicesAndSensorsStatusSortingOrder,
  openRecommendationAssetStatusSortingOrder,
}
