import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { DevicesAndSensorsContextProvider, useDevicesAndSensorsContext } from '@/contexts/devicesAndSensors'
import { DeviceInterface } from '@/models/devicesAndSensors/types'
import DevicesSensorsHeader from '@/modules/devicesSensors/components/header'
import { DevicesSensorsSideMenu } from '@/modules/devicesSensors/components/sideMenu'
import useGetDevicesAndSensorsData from '@/modules/devicesSensors/hooks/useGetDevicesAndSensorsData'
import { StyledDevicesAndSensorsModuleContainer } from '@/modules/devicesSensors/styled'
import useDevicesTable from '@/modules/devicesSensors/tables/useDevicesTable'
import useResetTableFilters from '@/shared/components/tableComponents/hooks/useResetTableFilters'
import ThemeConsumer from '@/shared/components/themeConsumer'
import { LoadingType } from '@/shared/hooks/useApi'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { dataTestId } from '@/tests/testid'
import { Flexbox } from '@skf-internal/ui-components-react'
import { MaterialReactTable } from 'material-react-table'
import { useEffect } from 'react'
import { useParams } from 'react-router'

const DevicesAndSensors = () => {
  return (
    <DevicesAndSensorsContextProvider>
      <InnerDevicesAndSensors />
    </DevicesAndSensorsContextProvider>
  )
}

const InnerDevicesAndSensors = () => {
  const { sid } = useParams()

  const { execute, reset, status } = useGetDevicesAndSensorsData()

  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  const { setDevicesAndSensorsSelectedItemsData, devicesAndSensorsSelectedItemsData, setDevicesAndSensorsData } =
    useDevicesAndSensorsContext()

  useDeepCompareEffect(() => {
    if (sid) {
      reset()
      execute({ loaderType: LoadingType.NONE })
    }
  }, [sid])

  useEffect(() => {
    setFetchDataFn(() => execute)
  }, [execute, setFetchDataFn])

  useDeepCompareEffect(() => {
    setFetchDataStatus(status)
  }, [status])

  useCleanupStorage()

  const devicesAndSensorsTable = useDevicesTable()
  const resetTableFilters = useResetTableFilters<DeviceInterface>(devicesAndSensorsTable)

  useDeepCompareEffect(() => {
    resetTableFilters()
    devicesAndSensorsTable.initialState.columnFilters = []
    if (devicesAndSensorsSelectedItemsData.device || devicesAndSensorsSelectedItemsData.channel) {
      setDevicesAndSensorsSelectedItemsData({
        device: undefined,
        channel: undefined,
      })
      setDevicesAndSensorsData([])
    }
  }, [sid])

  useDeepCompareEffect(() => {
    const tableContainer = devicesAndSensorsTable.refs.tableContainerRef.current
    if (tableContainer) {
      const firstRow = tableContainer.querySelector('tbody tr:first-of-type')
      if (firstRow) {
        ;(firstRow as HTMLTableRowElement).focus()
      }
    }
  }, [devicesAndSensorsTable])

  return (
    <ThemeConsumer>
      <Flexbox feGap="sm" data-testid={dataTestId.devicesSensorsModule}>
        <StyledDevicesAndSensorsModuleContainer feFlexDirection="column">
          <DevicesSensorsHeader table={devicesAndSensorsTable} />
          <MaterialReactTable table={devicesAndSensorsTable} />
        </StyledDevicesAndSensorsModuleContainer>
        <DevicesSensorsSideMenu />
      </Flexbox>
    </ThemeConsumer>
  )
}

export default DevicesAndSensors
