import {
  AllKeysOf,
  AssetCriticality,
  AssetStatusString,
  EvidenceImageInfo,
  ExtractArrayValues,
  RecommendedActionBase,
} from '@/shared/models/types'
import { AssetHealthStatusSelectType } from '@/models/dashboard/globalFiltering/types'
import { allAssetTypes, criticalityOptions, statusOptions } from '@/modules/report-generator/utils/constants'

export enum ReportType {
  summaryCharts = 'Summary Charts',
  summarizedAssetHealth = 'Summarized Asset Health',
  detailedAssetHealth = 'Detailed Asset Health',
  lastMeasurements = 'Last Measurements',
  openRecommendedActions = 'Open Recommended Actions',
}

export type ReportTypeString = AllKeysOf<ReportType>

export type StatusOptionValue = ExtractArrayValues<typeof statusOptions, 'value'>
export type CriticalityOptionValue = ExtractArrayValues<typeof criticalityOptions, 'value'>

export type AssetReportFilters = {
  assets: string[]
  assetStatuses: AssetHealthStatusSelectType[]
  assetCriticalities: AssetCriticality[]
  assetTypes: Array<'all' | (typeof allAssetTypes)[number]>
  reportTypes: ReportTypeString[]
  reportDate: Date
}
export type ReportGeneratorApiPayload = Omit<AssetReportFilters, 'reportDate'> & {
  reportDate: string
}

export interface IReportSelect<R> {
  value: R[]
  onChange: (value: R[]) => void
}

export type ReportAssetHealthDetails = {
  assetId: string
  assetName: string
  assetLocation: { siteName: string; parentName: string }
  assetDescription: string | null
  assetType: string | null
  assetCriticality: AssetCriticality
  assetStatus: AssetStatusString
  currentConditionReported: Date
  lastCollectionDate: Date
  reporterName: string
  statusHistory: AssetStatusHistory
  faults: ReportFault[]
  lastMeasurements: Measurement[]
}

export type Period = {
  startYear: string
  endYear: string
}

export type HistoryEntry = {
  date: string
  assetStatus: AssetStatusString
  reportCount: number
}

export type AssetStatusHistory = {
  period: Period
  history: HistoryEntry[]
}

export type DetailedAssetEvidence = Omit<
  EvidenceImageInfo,
  'contentType' | 'size' | 'lastModified' | 'uniqueId' | 'originFile' | 'content'
>

export type DetailedAssetRecommendedAction = Omit<RecommendedActionBase, 'additionalNotes' | 'recommendedAction'> & {
  comment: string
  raId: string
  recommendation: string
}

export type ReportFault = {
  faultId: string
  faultType: string
  faultStatus: AssetStatusString
  dateReported: Date
  dataCollectedDate: Date
  observation: string
  evidences: DetailedAssetEvidence[]
  recommendedActions: DetailedAssetRecommendedAction[]
}
export type StatusValue = {
  value: AssetStatusString
  testId: string
}

export type Measurement = {
  pointId: string
  pointName: string
  previousValue: string
  lastValue: string
  units: string
}

type CoverPage = {
  companyName: string
  siteName: string
  address: string
  createdUser: string
  reportedDate: Date
  logoFileURL: string
}

export type ReportGeneratorApiResponse = {
  coverPage: CoverPage
  summaryCharts: Record<string, unknown> //will be updated in specific api integration ticket
  summarizedAssetHealth: Record<string, unknown>[] //will be updated in specific api integration ticket
  detailedAssetHealth: ReportAssetHealthDetails[] //will be updated in specific api integration ticket
  openRecommendedActions: Record<string, unknown>[] //will be updated in specific api integration ticket
}

export type ReportAssetStatusIconImages = {
  assets: string[]
  assetStatuses: AssetHealthStatusSelectType[]
  assetCriticalities: AssetCriticality[]
  assetTypes: Array<'all' | (typeof allAssetTypes)[number]>
  reportTypes: ReportTypeString[]
  reportDate: Date
}
