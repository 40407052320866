import { useAnalysisBacklogContext } from '@/contexts/analysisBacklog'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { InitialAsset } from '@/models/analysisBacklog/types'
import useAnalysisBacklogColumns from '@/modules/analysisBacklog/columns/useAnalysisBacklogColumns'
import AnalysisBacklogActionMenu from '@/modules/analysisBacklog/components/cell/actionMenu'
import { AnalysisBacklogMenuProps } from '@/modules/analysisBacklog/components/cell/actionMenu/types'
import EmptyAssetTableState from '@/shared/components/tableComponents/emptyState'
import {
  analysisBacklogTableBodyCellProps,
  analysisBacklogTableBodyRowProps,
  analysisBacklogTableContainerProps,
  analysisBacklogTableHeadCellProps,
  analysisBacklogTableHeaderProps,
  analysisBacklogTablePaperProps,
} from '@/modules/analysisBacklog/styled/tableSx'
import useHandleTableDataChange from '@/shared/components/tableComponents/hooks/useHandleTableDataChange'
import usePreviousData from '@/shared/components/tableComponents/hooks/usePreviousData'
import useTableIcons from '@/shared/components/tableComponents/hooks/useTableIcons'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { createCustomSortHandler } from '@/shared/utils'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import {
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_SortingState,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useMemo, useState } from 'react'
import AllProcessedState from '@/shared/components/tableComponents/allProcessedState'

const useAnalysisBacklogTable = () => {
  const { analysisBacklogData: assets } = useAnalysisBacklogContext()
  const [sorting, setSorting] = useState<MRT_SortingState>([])

  const [currentSelectedRow, setCurrentSelectedRow] = useState<InitialAsset | undefined>(undefined)
  const { fetchDataStatus } = useFetchDataContext()
  const sortingChangeHandler = useMemo(() => createCustomSortHandler(3), [])

  const { tableStateChangeSwitchCurrentState: showHandled } = useTypedSelector((state) =>
    headerActionPanelConfigSelector(state)
  )

  const columns = useAnalysisBacklogColumns()
  const tableData = useDeepCompareMemo(() => {
    let data = assets.unhandled
    if (showHandled) {
      data = [...assets.unhandled, ...assets.handled]
    }
    return data
  }, [assets, showHandled])

  const tableIcons = useTableIcons()

  const isAllHandled = useMemo(
    () => assets.unhandled.length === 0 && assets.handled.length > 0,
    [assets.handled.length, assets.unhandled.length]
  )

  const showSkeletons = useDeepCompareMemo(() => {
    let result = false
    if (
      fetchDataStatus === 'loading' ||
      (tableData.length === 0 && fetchDataStatus !== 'success' && fetchDataStatus !== 'error' && !isAllHandled)
    ) {
      result = true
    }

    return result
  }, [fetchDataStatus, tableData, isAllHandled])

  let sortedRows: MRT_Row<InitialAsset>[] = []
  let tableColumnFilters: MRT_ColumnFiltersState = []
  let tableSorting: MRT_SortingState = []

  const analysisBacklogTable = useMaterialReactTable({
    columns,
    data: tableData,
    enableRowVirtualization: false,
    enableTopToolbar: false,
    enablePagination: tableData.length !== 0,
    enableBottomToolbar: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    onSortingChange: (updater) => {
      setSorting(sortingChangeHandler(updater))
    },
    enableColumnFilters: true,
    columnFilterDisplayMode: 'custom',
    enableKeyboardShortcuts: false,
    initialState: { pagination: { pageIndex: 0, pageSize: 100 } },
    isMultiSortEvent: () => true,
    state: {
      showSkeletons,
      sorting,
    },
    renderEmptyRowsFallback: useCallback<
      Required<MRT_TableOptions<InitialAsset>>['renderEmptyRowsFallback'] //TS needed to get the correct type of the inner arrow function below
    >(
      ({ table }) => {
        let result = <EmptyAssetTableState<InitialAsset> table={table} />
        if (isAllHandled) {
          result = <AllProcessedState />
        }
        return result
      },
      [isAllHandled]
    ),
    icons: tableIcons,
    muiTableHeadProps: analysisBacklogTableHeaderProps,
    muiTablePaperProps: analysisBacklogTablePaperProps,
    muiTableContainerProps: analysisBacklogTableContainerProps,
    muiSkeletonProps: {
      height: 50,
    },
    defaultColumn: {
      muiTableBodyCellProps: analysisBacklogTableBodyCellProps,
      muiTableHeadCellProps: () => analysisBacklogTableHeadCellProps(fetchDataStatus !== 'loading'),
    },
    muiTableBodyRowProps: ({ row }) => {
      const rowIndex: number = sortedRows.findIndex((item) => item.id === row.id)
      const nextKeydownIndex: number = sortedRows.findIndex((item) => item.original.id === currentSelectedRow?.id)
      const isSelected = currentSelectedRow?.id === row.original.id

      return {
        ...analysisBacklogTableBodyRowProps(false, rowIndex, isSelected, showSkeletons),
        tabIndex: 0,
        onKeyDown: (e) => onDeviceTableRowKeyDown(e, row, nextKeydownIndex),
        onClick: () => setCurrentSelectedRow(row.original),
      }
    },
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'text',
      onClick: () => {
        analysisBacklogTable.refs.tableContainerRef.current?.scrollTo({ top: 0 })
      },
    },
    paginationDisplayMode: 'pages',
    enableRowActions: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 50,
        grow: false,
      },
    },
    renderRowActions: useCallback<
      Required<MRT_TableOptions<InitialAsset>>['renderDetailPanel'] //TS needed to get the correct type of the inner arrow function below
    >(({ row, table }) => {
      const { original } = row
      const actionElements: AnalysisBacklogMenuProps['elements'] =
        original.status === 'never-reported' || original.status === 'normal'
          ? ['mark-as-normal', 'report-status']
          : ['report-status']
      return (
        <AnalysisBacklogActionMenu
          currentSelectedRowIndex={row.index}
          assetRow={original}
          elements={actionElements}
          table={table}
        />
      )
    }, []),
  })

  const tableState = analysisBacklogTable.getState()

  tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  sortedRows = useDeepCompareMemo(
    () => analysisBacklogTable.getSortedRowModel().rows as MRT_Row<InitialAsset>[],
    [assets, tableSorting, tableColumnFilters, showHandled]
  )

  const onDeviceTableRowKeyDown = useDeepCompareCallback(
    (event: React.KeyboardEvent<HTMLTableRowElement>, row: MRT_Row<InitialAsset>, rowIndex: number) => {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        if (event.key === 'ArrowUp' && rowIndex === 0) return
        if (event.key === 'ArrowDown' && rowIndex === sortedRows.length - 1) return

        if (event.key === 'ArrowDown') {
          setCurrentSelectedRow(sortedRows[rowIndex + 1].original)
        }
        if (event.key === 'ArrowUp') {
          setCurrentSelectedRow(sortedRows[rowIndex - 1].original)
        }
      }
    },
    [assets, tableSorting, tableColumnFilters]
  )

  usePreviousData<InitialAsset, InitialAsset[]>({
    table: analysisBacklogTable,
    setCurrentSelectedRow,
    tableData,
    setSorting,
  })

  useHandleTableDataChange<InitialAsset, InitialAsset[]>({
    table: analysisBacklogTable,
    setCurrentSelectedRow,
    tableData,
  })

  return analysisBacklogTable
}

export default useAnalysisBacklogTable
