import { FC } from 'react'
import { Image, StyleSheet, Styles, Text, View } from '@react-pdf/renderer'
import { detailedAssetHealth } from '@/modules/report-generator/utils/detailedAssetHealthPDFStyles'
import { AssetStatusString } from '@/shared/models/types'
import { getAssetStatusImageSrc } from '@/modules/report-generator/utils'
import { dataTestId } from '@/tests/testid'
import { capitalizeFirstLetter } from '@/shared/utils'

const styles = StyleSheet.create(detailedAssetHealth as Styles)

interface ReportStatusFieldProps {
  label: string
  status: AssetStatusString
}

const ReportStatusField: FC<ReportStatusFieldProps> = ({ label, status }) => {
  return (
    <View>
      <Text style={styles.label}>{label}</Text>
      <View style={styles.valueWithIcon}>
        <Image
          style={styles.iconSize}
          src={getAssetStatusImageSrc(status) as string}
          data-testid={dataTestId.reportStatusIconImage}
        />
        <Text style={styles.value}>{capitalizeFirstLetter(status)}</Text>
      </View>
    </View>
  )
}

export default ReportStatusField
