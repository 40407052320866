import { FC } from 'react'
import PdfTemplate from '@/modules/report-generator/components/reports/pdftemplate'
import ReportAssetDetails from '@/modules/report-generator/components/reports/detailedAssetHealth/reportAssetDetails'
import AssetFaultPDFPreview from '@/modules/report-generator/components/reports/detailedAssetHealth/reportAssetFault'
import ReportAssetStatusHistory from '@/modules/report-generator/components/reports/detailedAssetHealth/reportAssetStatusHistory'
import { ReportGeneratorApiResponse } from '@/models/reportGenerator/types'

interface DetailedAssetHealthPDFPreviewProp {
  reportAssetHealthDetailsData: ReportGeneratorApiResponse['detailedAssetHealth']
}

const DetailedAssetHealthPDFPreview: FC<DetailedAssetHealthPDFPreviewProp> = ({ reportAssetHealthDetailsData }) => {
  if (!reportAssetHealthDetailsData || reportAssetHealthDetailsData.length === 0) {
    return null
  }
  return (
    <>
      {reportAssetHealthDetailsData.map(({ faults, statusHistory, ...assetDetails }) => (
        <PdfTemplate key={assetDetails.assetId}>
          <ReportAssetDetails assetDetails={assetDetails} />
          <ReportAssetStatusHistory statusHistory={statusHistory} />
          {faults.length > 0 && faults.map((fault) => <AssetFaultPDFPreview key={fault.faultId} fault={fault} />)}
        </PdfTemplate>
      ))}
    </>
  )
}

export default DetailedAssetHealthPDFPreview
