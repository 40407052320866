import {
  AssetReportFilters,
  CriticalityOptionValue,
  ReportGeneratorApiResponse,
  ReportType,
  ReportTypeString,
} from '@/models/reportGenerator/types'
import { SelectItem } from '@skf-internal/ui-components-react'
import { initialReportFiltersState, mappedAssetStatusImages } from './constants'
import { AssetCriticality, AssetStatusString } from '@/shared/models/types'
import { getMappedValue } from '@/shared/utils'
import { FaultTypes } from '@/models/reportStatus/types'

/**
 * Returns an array of asset criticality types based on the given criticality option value.
 * @param {CriticalityOptionValue} value - The selected criticality option.
 * @returns {AssetCriticality[]} - An array of criticality types matching the given value.
 */
const getCriticality = (value: CriticalityOptionValue): AssetCriticality[] => {
  switch (value) {
    case 'all':
      return ['A', 'B', 'C']
    case 'a':
      return ['A']
    case 'ab':
      return ['A', 'B']
    case 'bc':
      return ['B', 'C']
    default:
      return []
  }
}

/**
 * Maps an array of asset criticality types to a specific criticality option value.
 * @param {AssetCriticality[]} criticality - An array of asset criticality types to map.
 * @returns {CriticalityOptionValue} - The criticality option value that represents the provided criticality types.
 */
const mapCriticalityOption = (criticality: AssetCriticality[]): CriticalityOptionValue => {
  switch (true) {
    case criticality.length === 1 && criticality.every((status) => ['A'].includes(status)):
      return 'a'
    case criticality.length === 2 && criticality.every((status) => ['A', 'B'].includes(status)):
      return 'ab'
    case criticality.length === 2 && criticality.every((status) => ['B', 'C'].includes(status)):
      return 'bc'
    default:
      return 'all'
  }
}

/**
 * Returns the report type enum value based on the provided report type string.
 * @param {ReportTypeString} reportType - The string representation of a report type.
 * @returns {ReportType} - The corresponding report type enum value.
 */
const getReportType = (reportType: ReportTypeString): ReportType => {
  switch (reportType) {
    case 'summaryCharts':
      return ReportType.summaryCharts
    case 'summarizedAssetHealth':
      return ReportType.summarizedAssetHealth
    case 'detailedAssetHealth':
      return ReportType.detailedAssetHealth
    case 'lastMeasurements':
      return ReportType.lastMeasurements
    default:
      return ReportType.openRecommendedActions
  }
}

/**
 * Utility function to transform options into SelectItem format.
 * @param options - The array of options with `label` and `value`.
 * @returns Transformed array of SelectItem objects.
 */
const mapToSelectItems = <T extends string>(options: ReadonlyArray<{ label: string; value: T }>): SelectItem<T>[] => {
  return options.map((option) => ({
    label: option.label,
    value: option.value,
  }))
}

/**
 * Clears all report generator filters by resetting them to the initial state.
 *
 * @param {React.Dispatch<React.SetStateAction<AssetReportFilters>>} setAssetReportFilters -
 * The state setter function for updating asset report filters.
 *
 */

const clearReportGeneratorFilters = (
  setAssetReportFilters: React.Dispatch<React.SetStateAction<AssetReportFilters>>
) => {
  setAssetReportFilters(initialReportFiltersState)
}

/**
 * Updates the report type in the asset report filters based on the provided status and its checked state.
 * If the status is 'lastMeasurements' and it is checked, it will automatically add 'detailedAssetHealth'
 * to the report type list if not already included. Similarly, if 'detailedAssetHealth' is unchecked,
 * 'lastMeasurements' will be removed if it exists in the list.
 *
 * @param {React.Dispatch<React.SetStateAction<AssetReportFilters>>} setAssetReportFilters -
 * A React state setter function for updating the asset report filters. It allows updating the `reportType` in
 * the state based on the previous state.
 *
 * @param {ReportTypeString} status - The report type status to add or remove. Can be any valid `ReportTypeString`.
 *
 * @param {boolean} checked - A boolean value indicating whether the status should be added (`true`) or removed (`false`).
 * If `true`, the status will be added to the report types. If `false`, it will be removed.
 *
 * @returns {void} - This function does not return anything, it updates the state of the `reportType` in the
 * `assetReportFilters`.
 */

const updateReportType = (
  setAssetReportFilters: React.Dispatch<React.SetStateAction<AssetReportFilters>>,
  status: ReportTypeString,
  checked: boolean
) => {
  setAssetReportFilters((prevState) => {
    let updatedReportType = [...prevState.reportTypes]

    if (checked) {
      updatedReportType.push(status)

      if (status === 'lastMeasurements' && !updatedReportType.includes('detailedAssetHealth' as ReportTypeString)) {
        updatedReportType.push('detailedAssetHealth' as ReportTypeString)
      }
    } else {
      updatedReportType = updatedReportType.filter((type) => type !== status)

      if (status === 'detailedAssetHealth') {
        updatedReportType = updatedReportType.filter((type) => type !== 'lastMeasurements')
      }
    }

    return { ...prevState, reportTypes: updatedReportType }
  })
}

/**
 * Returns the corresponding PNG image source for a given asset status.
 *
 * This function maps an asset status string to its respective PNG image path.
 * If the status is not found in the mapping, it returns `null`.
 *
 * @param {AssetStatusString} status - The asset status string (e.g. "normal", "severe", "acceptable").
 * @returns {string | null} The image source URL if found, otherwise `null`.
 */

const getAssetStatusImageSrc = (status: AssetStatusString): string | null => {
  return mappedAssetStatusImages[status] || null
}

/**
 * Maps asset health data fault types to their corresponding values using the provided mapping.
 *
 * @param {ReportGeneratorApiResponse['detailedAssetHealth']} data - The asset health data array.
 * @param {FaultTypes} reportFaultTypes - An object mapping fault type keys to their corresponding values.
 * @returns {Array} The transformed asset health data with mapped fault types.
 */

const mapAssetHealthDataFaultTypes = (
  data: ReportGeneratorApiResponse['detailedAssetHealth'],
  reportFaultTypes: FaultTypes
) => {
  return data.map((asset) => ({
    ...asset,
    faults: asset.faults.map((fault) => ({
      ...fault,
      faultType: getMappedValue(reportFaultTypes, fault.faultType),
    })),
  }))
}

export {
  getCriticality,
  mapCriticalityOption,
  getReportType,
  mapToSelectItems,
  clearReportGeneratorFilters,
  updateReportType,
  getAssetStatusImageSrc,
  mapAssetHealthDataFaultTypes,
}
