import React, { useEffect, useMemo } from 'react'
import {
  StyledReportPreviewContainer,
  StyledReportPreviewEmptyState,
  StyledReportPreviewPDFViewer,
  StyledReportPreviewLoaderOverlay,
  StyledReportPreviewLoaderContainer,
  StyledReportPreviewPDFLogo,
} from '@/modules/report-generator/styled'
import { Button, Loader } from '@skf-internal/ui-components-react'
import { Text } from '@react-pdf/renderer'
import PDF from '@/modules/report-generator/assets/pdf_4x.webp'
import Report from '@/modules/report-generator/components/reports'
import { dataTestId } from '@/tests/testid'
import CoverPage from '@/modules/report-generator/components/reports/coverPage'
import InfoPage from '@/modules/report-generator/components/reports/infoPage'
import PDFTemplateHeaderLogo from '@/modules/report-generator/components/reports/pdftemplate/pdfTemplateHeaderLogo'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import useGenerateReports from '@/modules/report-generator/hooks/usePostGenerateReports'
import { useParams } from 'react-router'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { generateUUID } from '@/shared/utils'

const ReportPreview: React.FC = () => {
  const sid = useParams()
  const { execute: generateReports, status: generateReportsStatus } = useGenerateReports()
  const { generatedReportData, showPreview, setShowPreview } = useReportGeneratorContext()
  const { setFetchDataFn, setFetchDataStatus, fetchDataStatus } = useFetchDataContext()
  const { assetReportFilters } = useReportGeneratorContext()
  const handleGenerateReport = async () => {
    setShowPreview(false)
    await generateReports()
  }

  useEffect(() => {
    if (generateReportsStatus === 'success') {
      setShowPreview(true)
    }
    setFetchDataStatus(generateReportsStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateReportsStatus])

  useEffect(() => {
    setShowPreview(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    setFetchDataFn(() => generateReports)
  }, [generateReports, setFetchDataFn])

  const isLoading = useMemo(() => fetchDataStatus === 'loading', [fetchDataStatus])

  return (
    <>
      {/* PDF HTML hidden components to render for preview generation after converting into image ::Start */}
      <PDFTemplateHeaderLogo />
      <CoverPage coverPageDetails={generatedReportData.coverPage} />
      <InfoPage />
      {/* PDF HTML hidden components to render for preview generation after converting into image ::End */}

      <StyledReportPreviewContainer
        feFlexDirection="column"
        feAlignItems="center"
        feJustifyContent="center"
        data-testid={dataTestId.generateReportPreview}
      >
        {isLoading && (
          <StyledReportPreviewLoaderOverlay>
            <StyledReportPreviewLoaderContainer data-testid={dataTestId.generateReportPreviewLoader}>
              <Loader feSize="md" />
              <Text>Generating report...</Text>
            </StyledReportPreviewLoaderContainer>
          </StyledReportPreviewLoaderOverlay>
        )}

        {!showPreview && !isLoading && (
          <StyledReportPreviewEmptyState feFlexDirection="column" feAlignItems="center" feJustifyContent="center">
            <StyledReportPreviewPDFLogo src={PDF} data-testid={dataTestId.generateReportPreviewLoader} />
            <Button
              onClick={handleGenerateReport}
              feSize="md"
              disabled={isLoading || assetReportFilters.reportTypes.length === 0}
              data-testid={dataTestId.generateReportButton}
            >
              Generate Report
            </Button>
          </StyledReportPreviewEmptyState>
        )}

        {showPreview && !isLoading && generatedReportData.coverPage.siteName && (
          <StyledReportPreviewPDFViewer showToolbar={false} data-testid={dataTestId.generateReportPreviewPDFViewer}>
            <Report key={generateUUID()} generatedReportData={generatedReportData} />
          </StyledReportPreviewPDFViewer>
        )}
      </StyledReportPreviewContainer>
    </>
  )
}

export default ReportPreview
